@use "../../styles/variables";

.signInError {
  margin-bottom: 32px;
  padding: 8px 16px;

  background: rgb(248 205 205 / 0.1);
  border: 1px solid #ffc9c9;
  border-radius: 7px;

  button {
    font-family: "Space Grotesk";
    font-size: 12px;
    font-weight: 400;
    color: variables.$dark-text;
    text-decoration: underline;
  }
}

body:global(.dark) {
  .signInError {
    text-align: left;

    background: variables.$light-red;
    border: 1px solid variables.$middle-red;

    button {
      color: variables.$default-text;
    }
  }
}
