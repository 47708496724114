@use "../../styles/variables";

.hint {
  position: relative;

  width: 16px;
  height: 16px;

  svg {
    cursor: pointer;

    &:hover {
      & + .text {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

.text {
  position: absolute;
  z-index: 10;
  bottom: -3px;
  left: calc(100% + 8px);

  padding: 8px 16px;

  font-size: 12px;
  font-weight: 400;
  line-height: 18px;

  visibility: hidden;
  opacity: 0;
  background-color: variables.$light-bg;
  border: 1px solid variables.$light-accent;
  border-radius: 7px;
  box-shadow: 0 4px 10px rgb(68 61 255 / 0.05);

  transition: 0.3s opacity;

  a {
    color: variables.$purple;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.rightTop {
  &.text {
    top: -3px;
    bottom: auto;
  }
}

body:global(.dark) {
  .hint {
    svg path:first-child {
      fill: variables.$light-purple;
      stroke: transparent;
    }

    .text {
      color: variables.$default-text;

      background-color: variables.$background;
      border-color: variables.$accent;
    }
  }
}
