@use "../../styles/variables";

.headerPlate {
  display: flex;
  justify-content: space-between;

  border-bottom: 1px solid variables.$light-hover;
}

.leftSide {
  display: flex;
}

.logoWrapper {
  display: flex;
  align-items: center;

  padding: 4px 16px;

  border-right: 1px solid variables.$light-hover;
}

.logo {
  width: 114px;
  height: 28px;

  path {
    &:last-of-type {
      fill: variables.$dark-text;
    }
  }
}

.coinRates,
.btns {
  display: flex;
  align-items: center;
  justify-content: center;
}

.coinRates {
  gap: 24px;

  padding: 0 16px;
}

.userProfileButton {
  flex-shrink: 0;

  margin-right: 24px;
  padding: 8px;

  border-radius: 5px;

  &:hover {
    background-color: variables.$light-bg;
  }

  &:active {
    background-color: variables.$light-hover;
  }
}

.notepadButton {

}

.signOut {
  display: flex;
  gap: 8px;
  align-items: center;

  height: 48px;
  padding: 0 18px 0 16px;

  background-color: variables.$light-hover;

  transition: background-color 0.3s ease;

  &:hover {
    background-color: variables.$light-accent;
  }
}

.signOutText {
  font-size: 14px;
  font-weight: 500;
  line-height: 18.2px;
  color: variables.$dark-text;
  letter-spacing: -0.03em;
}

.signOutIcon {
  path {
    stroke: variables.$dark-text;
  }
}

.smallBtns {
  display: flex;
  gap: 12px;

  margin-right: 24px;
}

.user {
  display: flex;
  gap: 10px;
  align-items: center;

  margin-right: 16px;
  padding: 0 12px;
}

.userText {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.03em;
}

.themeSwitcher {
  margin-right: 16px;
}

.docsButton {
  path {
    fill: variables.$dark-text;
  }
}

.myEnvironmentButton {
  path {
    fill: variables.$dark-text;
    stroke: variables.$dark-text;
  }
}

.profileButton {
  path {
    fill: variables.$dark-text;
    stroke: variables.$dark-text;
  }
}

.notepadButton {
  margin-right: 16px;
}

.notepadButtonIcon {
  rect {
    fill: variables.$dark-text;
    stroke: variables.$dark-text;
  }

  path {
    stroke: variables.$white;
  }

  path:nth-of-type(3),
  path:nth-of-type(4) {
    stroke: variables.$dark-text;
  }
}

.userIcon {
  path {
    fill: variables.$dark-text;
  }
}

.signOut {
  border-left: 1px solid variables.$light-hover;
}

.leftBtns {
  background-color: variables.$purple;
  border-left: 1px solid variables.$light-hover;
  .signOutText { color: variables.$white; }

  &:hover {
    background-color: variables.$middle-purple;
  }
}

.profile {
  background-color: transparent;

  .signOutText {
    color: variables.$dark-text;
  }

  svg path {
    fill: variables.$dark-text;
  }

  &:hover {
    .signOutText {
      color: variables.$white;
    }

    svg path {
      fill: variables.$white;
    }
  }
}

body:global(.dark) {
  .headerPlate {
    border-bottom: 1px solid variables.$hover;
  }

  .logoWrapper {
    border-right: 1px solid variables.$hover;
  }

  .logo {
    width: 114px;
    height: 28px;

    path {
      &:last-of-type {
        fill: variables.$white;
      }
    }
  }

  .userProfileButton {
    &:hover {
      background-color: variables.$ui-elements;
    }

    &:active {
      background-color: variables.$hover;

      & > div {
        border-color: rgb(255 255 255 / 0.2);
      }
    }
  }

  .signOut {
    background-color: variables.$ui-elements;
    border-left: 1px solid variables.$background;

    &:hover {
      background-color: variables.$middle-purple;
    }
  }

  .signOutText {
    color: variables.$white;
  }

  .signOutIcon {
    path {
      stroke: variables.$white;
    }
  }

  .docsButton {
    path {
      fill: variables.$white;
    }
  }

  .myEnvironmentButton {
    path {
      fill: variables.$white;
      stroke: variables.$white;
    }
  }

  .profileButton {
    path {
      fill: variables.$white;
      stroke: variables.$white;
    }
  }

  .notepadButton {
    rect {
      fill: variables.$white;
      stroke: variables.$white;
    }

    path {
      stroke: variables.$hover;
    }

    path:nth-of-type(3),
    path:nth-of-type(4) {
      stroke: variables.$white;
    }
  }

  .userIcon {
    path {
      fill: variables.$white;
    }
  }

  .leftBtns {
    background-color: variables.$purple;
    border-left: 1px solid variables.$background;
  }

  .profile {
    background-color: transparent;

    svg path {
      fill: variables.$white;
    }
  }
}
