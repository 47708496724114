@use "../../styles/variables";

.downloadReportBackdrop {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;

  width: 100%;
  height: 100vh;

  visibility: hidden;
  opacity: 0;

  transition: opacity 0.1s, visibility 0.1s;

  &.visible {
    visibility: visible;
    opacity: 1;
  }
}

.downloadReport {
  position: absolute;
  z-index: 101;

  width: 433px;
  padding: 16px;

  background-color: variables.$white;
  border: 2px solid variables.$purple;
  border-radius: 5px;
  box-shadow: 0 4px 30px rgb(54 56 84 / 0.25);
}

.heading {
  margin-bottom: 25px;
}

.positions {
  margin-bottom: 8px;
  padding: 8px;

  border-radius: 5px;

  &.isChecked {
    background-color: #f0f4ff;
  }
}

.divider {
  height: 1px;
  margin-top: 16px;
  margin-bottom: 16px;

  background-color: variables.$light-hover;
}

.comingSoon {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 14px;
  padding-left: 8px;

  &:last-of-type {
    margin-bottom: 25px;
  }
}

.close {
  position: absolute;
  top: 12px;
  right: 12px;

  width: 24px;
  height: 24px;

  background-image: url("../../assets/images/x.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.downloadButton {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.spinner {
  animation-name: spin;
  animation-duration: 5000ms;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

body:global(.dark) {
  .downloadReport {
    background-color: variables.$panels-bg;
    box-shadow: none;
  }

  .positions {
    &.isChecked {
      background-color: variables.$ui-elements;
    }
  }

  .close {
    background-image: url("../../assets/images/xDarkTheme.svg");
  }
}
