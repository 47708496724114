.myEnvironmentCards {
  position: relative;

  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  padding: 24px;
  padding-top: 0;
}
