@use "../../styles/variables";

.card {
  padding: 12px 16px;

  background-color: variables.$white;
  border: 1px solid variables.$light-accent;
  border-radius: 5px;
}

.heading {
  margin-bottom: 4px;

  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  text-transform: uppercase;
}

body:global(.dark) {
  .card {
    background-color: variables.$panels-bg;
    border-color: variables.$accent;
  }
}
