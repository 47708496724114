@use "../../styles/variables";

.legenda {
  position: relative;

  padding-left: 10px;

  &::before {
    content: "";

    position: absolute;
    top: 0;
    left: 0;

    display: block;

    width: 6px;
    height: 16px;

    border-radius: 2px;
  }

  &.exchanges::before {
    background-color: variables.$green;
  }

  &.portfolio::before {
    background-color: variables.$light-purple;
  }
}

.text {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
}
