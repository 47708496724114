@use "../../../../styles/variables";

.card {
  display: flex;
  flex-basis: calc(33.3% - 11px);
  flex-direction: column;
  justify-content: space-between;

  height: 99px;
  padding: 12px;
}

.cardName {
  margin-bottom: 4px;

  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.input {
  max-width: 132px;
}

.cardNameLine {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.exchanges {
  margin-bottom: 10px;

  font-size: 12px;
  line-height: 14px;
  color: variables.$secondary-text;
}

.status {
  display: flex;
  gap: 6px;
}

.statusText {
  font-size: 12px;
  line-height: 14px;
}

.statusColor {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 14px;
  height: 14px;

  border-radius: 2px;

  span {
    font-size: 10px;
    font-weight: 600;
    color: variables.$white;
  }
}

.statusGreen {
  background-color: variables.$green;
}

.statusOrange {
  background-color: variables.$orange;
}

.statusWrapper {
  display: flex;
  gap: 12px;
}

.manage {
  display: flex;
  gap: 6px;
  align-items: center;

  span {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }

  svg {
    width: 5px;
    height: auto;

    path {
      transition: stroke 0.1s;
    }
  }

  &:hover {
    span {
      color: variables.$purple;
    }

    svg path {
      stroke: variables.$purple;
    }
  }

  &:active {
    span {
      color: variables.$dark-purple;
    }

    svg path {
      stroke: variables.$dark-purple;
    }
  }
}

.bottomLine {
  display: flex;
  justify-content: space-between;
}

.customViewName {
  display: flex;
  gap: 12px;
  align-items: flex-start;
}

.updateTime {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: #9b9ca9;
}

.icon {
  width: 38px;
  height: auto;

  border-radius: 5px;
}

.cardType {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: #9b9ca9;
}

.renameBtnWrapper {
  display: flex;
  gap: 4px;
}

.renameBtn {
  align-items: center;
  justify-content: center;

  width: 28px;
  height: 24px;
  padding: 0;

  border-radius: 5px;
}

.renameWrapper {
  display: flex;
  gap: 8px;
}

body:global(.dark) {
  .card {
    border-color: variables.$ui-elements;
  }

  .manage {
    svg path {
      stroke: variables.$default-text;
    }

    &:hover {
      span {
        color: variables.$middle-purple;
      }

      svg path {
        stroke: variables.$middle-purple;
      }
    }

    &:active {
      span {
        color: variables.$dark-purple;
      }

      svg path {
        stroke: variables.$dark-purple;
      }
    }
  }

  .crossIcon path {
    stroke: variables.$white;
  }

  .spinner {
    path {
      stroke: variables.$white;
    }
  }
}

.spinner {
  animation-name: spin;
  animation-duration: 5000ms;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
