@use "../../styles/variables";

.comingSoon {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 107px;
  height: 26px;

  background-color: #f0f4ff;
  border-radius: 5px;
}

.label {
  font-size: 12px;
  color: #abb8db;
}

body:global(.dark) {
  .comingSoon {
    background-color: variables.$ui-elements;
  }

  .label {
    color: variables.$light-blue-text;
  }
}
