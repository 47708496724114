@use "../../../../styles/variables";

.accountInfo {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 24px;

  border-bottom: 2px solid variables.$purple;
}

.accountInfoLeftSide {
  display: flex;
  gap: 24px;
  align-items: center;
}

.avatar {
  cursor: pointer;
}

.accountDetailsTop {
  display: flex;
  gap: 12px;
  align-items: center;

  margin-bottom: 4px;
}

.email {
  margin-right: 4px;
}

.status {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 55px;
  height: 22px;

  background-color: variables.$light-accent;
  border-radius: 3px;
}

.statusUpgradeBtn {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 55px;
  height: 22px;
}

.signOut {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;

  height: 40px;
  padding: 0 16px;

  border: 1px solid variables.$purple;
  border-radius: 10px;
}

.chooseAvatar {
  position: absolute;
  z-index: 100;
  top: 100%;
  left: 0;

  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 100%;
  height: 187px;
  padding: 24px;

  background-color: variables.$light-hover;
  border-top: 2px solid variables.$purple;
}

.avatars {
  display: flex;
  gap: 24px;
}

.buttons {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}

.actionButton {
  width: 88px;
}

.darkLeaveIcon {
  path {
    stroke: variables.$dark-text;
  }
}

body:global(.dark) {
  .accountInfo {
    background-color: variables.$panels-bg;
  }

  .status {
    background-color: variables.$ui-elements;
  }

  .statusPro {
    color: variables.$middle-purple;
  }

  .statusUpgrade {
    color: variables.$light-blue-text;
  }

  .chooseAvatar {
    background-color: variables.$panels-bg;
  }
}
