@import "https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap";

@import "https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap";

:root {
  --dark-purple: #0600b2;
  --middle-purple: #2219ff;
  --purple: #443dff;
  --light-purple: #7297ff;
  --blue-text: #7d90c4;
  --light-blue-text: #abb8db;
  --light-accent: #d1ddff;
  --light-hover: #e4ebff;
  --light-bg: #f0f4ff;
  --exchange-row: #e7edff;
  --portfolio-row: #f6f8ff;
  --table-header: #f7f9ff;
  --orange: #ff9344;
  --light-orange: #faf6ee;
  --red: #f00;
  --middle-red: #ff9c9c;
  --light-red: #fefafa;
  --green: #00b57e;
  --light-green: #ccf0e5;
  --black: #000;
  --dark-text: #363853;
  --grey: #4b4b4b;
  --secondary-text: #9b9ca9;
  --white: #fff;

  /* dark theme specific colors */
  --background: #18181a;
  --panels-bg: #262629;
  --ui-elements: #323236;
  --hover: #3d3d42;
  --accent: #4b4b52;
  --default-text: #e9e9ff;
  --header-text: #fff;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;

  background-color: var(--light-bg);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.dark {
  background-color: var(--background);
}

/* stylelint-disable-next-line selector-class-pattern */
.ScrollbarsCustom-Track:hover {
  opacity: 0.4 !important;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html {
  width: 100%;
  height: 100%;

  font-family: "Space Grotesk", Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: 16px;
}

* {
  user-select: none;

  box-sizing: border-box;
}

.app {
  display: flex;

  width: 100%;
  min-height: 100vh;
}

.content {
  flex: 0 0 100%;

  padding: 28px 32px;
  padding-left: 400px;

  transition: padding-left 0.3s;
}

.expanded-content {
  padding-left: 116px;
}

a {
  margin: 0;
  padding: 0;

  text-decoration: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;

  font-family: "Space Grotesk", sans-serif;
}

.number {
  font-family: Roboto, ui-monospace;
}

button {
  cursor: pointer;

  box-sizing: border-box;
  margin: 0;
  padding: 0;

  background: none;
  border: none;
  outline: none;
}

img {
  margin: 0;
  padding: 0;
}

table {
  border-spacing: 0;
}

input {
  box-sizing: border-box;
  height: 3.5rem;

  background: none;
  border: none;
}
