@use "../../../../styles/variables";

.tableHead {
  user-select: none;

  flex-shrink: 0;

  width: 102px;

  background-color: variables.$table-header;
  border: 1px solid variables.$light-hover;
  border-right: 0;

  &:nth-child(2) {
    border-left: none;
  }

  &.nameCell {
    position: sticky;
    z-index: 15;
    top: 0;
    left: 0;

    width: 320px !important;

    border-right: 1px solid variables.$light-hover;
  }

  &.wide {
    width: 143px;
  }

  &.standardCell {
    width: calc((100% - 320px) / 3);
  }
}

.fixed {
  width: 102px;
}

.withBorderRadius {
  &:first-child {
    border-top-left-radius: 5px;
  }

  &:last-child {
    border-right: 1px solid variables.$light-hover;
    border-top-right-radius: 5px;
  }
}

.controlCell {
  display: flex;
  justify-content: space-between;

  min-width: 250px;
  height: 100%;
  padding-right: 16px;
  padding-left: 44px;

  text-align: left;
}

.buttonsWrapper {
  display: flex;
  gap: variables.$space-unit-6;
}

.text {
  white-space: nowrap;
}

.contentWrapper {
  height: 32px;
  padding: 6px 12px;

  text-align: right;
}

.expandButton {
  &:hover {
    .expandButtonText {
      color: variables.$dark-text;
    }
  }

  &:active {
    .expandButtonText {
      color: variables.$purple;
    }
  }
}

body:global(.dark) {
  .tableHead {
    background-color: variables.$ui-elements;
    border-color: variables.$hover;
  }

  .text {
    color: variables.$default-text;
  }

  .expandButton {
    .expandButtonText {
      color: variables.$default-text;
    }

    &:hover {
      .expandButtonText {
        color: variables.$middle-purple;
      }
    }

    &:active {
      .expandButtonText {
        color: variables.$dark-purple;
      }
    }
  }
}
