@use "../../styles/variables";

.filterLine {
  flex-shrink: 0;

  &:hover {
    .filterLineText {
      color: variables.$purple;
    }
  }
}

.filterLineText {
  text-decoration: underline;
}

body:global(.dark) {
  .filterLine {
    &:hover {
      .filterLineText {
        color: variables.$light-blue-text;
      }
    }
  }
}
