@use "../../styles/_variables";

.modalNotepad {
  width: 700px;
}

.editWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: space-between;

  padding: 24px;
}

.inputsWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.buttons {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}

.actionButton {
  width: 100px;
}

.textarea {
  height: 421px;
}

.tableWrapper {
  padding: 0 24px;
}

.table {
  border-collapse: collapse;

  width: 100%;
  margin-bottom: 24px;

  color: variables.$dark-text;

  td {
    padding: 4px 8px;

    font-size: 12px;

    background-color: variables.$white;
    border: 1px solid variables.$light-hover;

    &:last-of-type {
      width: 32px;
      padding: 4px;
    }
  }

  thead td {
    font-weight: 700;
    color: variables.$purple;
    white-space: nowrap;

    background-color: variables.$table-header;

    &:last-of-type {
      background-color: transparent;
      border-top: none;
      border-right: none;
    }
  }
}

.kebab {
  & > div > div {
    padding-right: 24px;
  }
}

.deleteBackdrop {
  position: absolute;
  top: 64px;
  left: 0;

  width: 100%;
  height: calc(100% - 64px);

  background: rgb(variables.$white / 0.5);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.deleteWrapper {
  position: absolute;
  right: 0;
  bottom: 0;

  width: 100%;
  height: 117px;
  padding: 24px;

  background-color: variables.$light-hover;
  border-top: 2px solid variables.$purple;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.deleteHeading {
  margin-bottom: 16px;

  font-size: 22px;
  font-weight: 600;
  line-height: 29px;
  color: variables.$purple;
  text-transform: uppercase;
}

.confirmationText {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;

  em {
    font-style: normal;
    color: variables.$purple;
  }
}

.deleteMain {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttonsWrapper {
  display: flex;
  gap: 8px;
}

.deleteBtnYes {
  width: 88px;
}

.deleteBtnNo {
  width: 88px;
}

body:global(.dark) {
  .table {
    color: variables.$white;

    background-color: variables.$background;

    td {
      background-color: variables.$background;
      border: 1px solid variables.$hover;
    }

    thead td {
      color: variables.$white;
      white-space: nowrap;

      background-color: variables.$ui-elements;

      &:last-of-type {
        background-color: transparent;
        border-top: none;
        border-right: none;
      }
    }
  }

  .deleteBackdrop {
    background: rgb(0 0 0 / 0.6);
  }

  .deleteHeading {
    color: variables.$middle-purple;
  }

  .deleteWrapper {
    background-color: variables.$panels-bg;
  }

  .confirmationText {
    em {
      color: variables.$middle-purple;
    }
  }
}
