@use "../../styles/variables";

.sidebarButton {
  cursor: pointer;

  overflow: hidden;
  display: flex;
  gap: 9px;
  align-items: center;

  width: 100%;
  height: 54px;
  padding: 12px 61px;

  white-space: nowrap;

  border-radius: 10px;

  transition: background-color 0.1s;

  &.isActive {
    background-color: variables.$purple;
  }

  &:hover {
    background-color: variables.$light-bg;
  }

  &:active {
    background-color: variables.$light-hover;
  }

  &.isCollapsed {
    padding-right: 0;
    padding-left: 24px;

    transition: padding 0.3s;

    &.isNotMainView {
      padding-left: 13px;
    }

    .text {
      visibility: hidden;
      opacity: 0;
    }

    .altText {
      visibility: visible;
      opacity: 1;
    }
  }

  svg {
    flex-shrink: 0;
  }

  svg path {
    transition: fill 0.1s, stroke 0.1s;
  }
}

.isActive {
  .text {
    color: variables.$white;
  }

  &:hover {
    background-color: variables.$middle-purple;
  }

  &:active {
    background-color: variables.$purple;
  }

  .mainViewIcon path {
    fill: variables.$white;
    stroke: variables.$purple;
  }

  .customViewIcon path {
    stroke: variables.$white;
  }
}

.text {
  font-size: 22px;
  font-weight: 500;

  transition: color 0.1s, opacity 0.1s, visibility 0.1s;

  &.altText {
    visibility: hidden;
    opacity: 0;
  }
}

.isMainView {
  padding-left: 24px;
}

body:global(.dark) {
  .sidebarButton {
    &:hover {
      background-color: variables.$ui-elements;
    }

    &:active {
      background-color: variables.$hover;
    }
  }

  .isActive {
    &:hover {
      background-color: variables.$middle-purple;
    }
  }

  .mainViewIcon path {
    fill: variables.$default-text;
  }

  .customViewIcon path {
    stroke: variables.$default-text;
  }
}
