@use "../../styles/variables";

.myEnvironmentsCardsHeader {
  display: flex;
  justify-content: space-between;

  width: 100%;
  padding: 24px;
}

.nameWrapper {
  display: flex;
  gap: 12px;
}

.heading {
  margin-bottom: 4px;
}

.description {
  font-size: 14px;
  line-height: 18px;
}

.back {
  position: relative;
  top: 2px;
  transform: rotate(180deg);

  width: 12px;
  height: 24px;

  background-image: url("../../assets/images/custom-view-open.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.accountName {
  font-size: 14px;
}

.highlightedDescription {
  margin-left: 8px;

  font-size: 14px;
  line-height: 18px;
}

body:global(.dark) {
  .back {
    transform: rotate(0deg);

    background-image: url("../../assets/images/custom-view-open-dark-theme.svg");
  }

  .highlightedDescription {
    color: variables.$middle-purple;
  }
}
