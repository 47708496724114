@use "../../styles/variables";

.filterBtn {
  &.filterBtnActive {
    position: relative;

    &::after {
      content: "";

      position: absolute;
      bottom: -6px;

      display: block;

      width: 100%;
      height: 2px;

      background-color: variables.$purple;
    }

    .filterBtnText {
      color: variables.$purple;
    }

    .filterBtnCount {
      color: variables.$purple;
    }
  }

  &:hover {
    .filterBtnText,
    .filterBtnCount {
      color: variables.$purple;
    }
  }
}

body:global(.dark) {
  .filterBtn {
    .filterBtnText,
    .filterBtnCount {
      color: variables.$secondary-text;
    }

    &.filterBtnActive {
      &::after {
        background-color: variables.$middle-purple;
      }

      .filterBtnText,
      .filterBtnCount {
        color: variables.$default-text;
      }
    }

    &:hover {
      .filterBtnText,
      .filterBtnCount {
        color: variables.$light-blue-text;
      }
    }
  }
}
