@use "../../styles/variables";

.textInputWrapper {
  position: relative;

  display: flex;
  flex-direction: column;

  &.disabled {
    .textInput {
      cursor: not-allowed;

      color: #d1ddff;

      opacity: 0.7;
    }
  }
}

.small {
  .textInput {
    height: 24px;
    padding: 2px 8px;

    font-size: 16px;

    border-radius: 5px;
  }
}

.titleWrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}

.large {
  .textInput {
    height: 40px;
    padding: 10px 16px;
  }
}

.textInput {
  width: 100%;

  font-family: "Space Grotesk", Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  color: #363853;

  background: variables.$white;
  border: 1px solid transparent;
  border-radius: 10px;

  transition: 0.1s border-color, 0.1s background-color;

  &::placeholder {
    color: #9b9ca9;
  }

  &:hover {
    border-color: #7297ff;
  }

  &:focus {
    background-color: #f0f4ff;
    border-color: #7297ff;
    outline: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    box-shadow: 0 0 0 30px variables.$white inset !important;
  }
}

.title {
  font-size: 12px;
}

.disabledTitle {
  opacity: 0.5;
}

.flushed {
  pointer-events: none;

  .disabledTitle {
    opacity: 1;
  }
}

body:global(.dark) {
  .textInputWrapper {
    &.disabled {
      .textInput {
        color: variables.$secondary-text;

        background-color: variables.$ui-elements;
      }
    }

    &.flushed {
      .textInput {
        color: variables.$default-text;

        background-color: variables.$ui-elements;
        border-color: transparent;
      }
    }
  }

  .textInput {
    color: variables.$default-text;

    background-color: variables.$ui-elements;
    border-color: transparent;

    &:hover {
      background-color: variables.$hover;
      border-color: transparent;
    }

    &:focus {
      background-color: variables.$ui-elements;
      border-color: variables.$purple;
      outline: none;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      border: none !important;
      outline: none !important;
      box-shadow: 0 0 0 100px variables.$ui-elements inset !important;

      -webkit-text-fill-color: variables.$default-text !important;
    }

    &.dropdownInputInput {
      border: none;

      &:hover {
        background-color: variables.$ui-elements;
      }
    }
  }
}
