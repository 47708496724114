.messageBox {
  padding: 8px 16px;

  border-radius: 7px;
}

.messageBoxSuccess {
  background: white;
  border: 1px solid #00b57e;
}

.messageBoxError {
  background: rgb(248 205 205 / 0.1);
  border: 1px solid #ffc9c9;
}

.text {
  font-size: 12px;
  line-height: 18px;
}

body {

}

body:global(.dark) {
  .messageBoxSuccess {
    background-color: #414d49;
    border: 1px solid #00b57e;
  }

  .messageBoxError {
    background-color: #261f1f;
    border: 1px solid #803333;
  }
}
