@use "../../styles/variables";

.authorization {
  position: fixed;
  top: 0;
  left: 0;

  display: flex;

  width: 100vw;
  height: 100vh;
}

.lSide {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 71vw;
  height: 100vh;
  padding: 60px 440px 0 60px;

  background: linear-gradient(90deg, #000 0%, #039 100%);
}

.title {
  margin-bottom: 40px;

  font-family: "Space Grotesk";
  font-size: 40px;
  font-weight: 700;
  line-height: 51px;
  color: white;
  text-align: left;
  text-transform: uppercase;

  b {
    color: #05f;
  }
}

.description {
  font-family: "Space Grotesk";
  font-size: 18px;
  font-weight: 400;
  line-height: 30.6px;
  color: white;
  text-align: left;
}

.rSide {
  position: relative;

  width: 29vw;
  height: 100vh;

  background-color: variables.$white;
}

.slogan {
  width: 512px;
}

.sloganTitle {
  margin-bottom: 16px;

  font-size: 32px;
  font-weight: 700;
  line-height: 41.6px;
  color: variables.$white;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}

.sloganText {
  margin-bottom: 16px;

  font-size: 24px;
  line-height: 31.2px;
  color: #9692ff !important;
  letter-spacing: 0.03em;
}

.logo {
  flex-shrink: 0;

  width: 263.47px;
  height: 64.85px;
  margin-bottom: 145px;

  path {
    fill: variables.$white;
  }
}

.linkToRisks {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 135px;

  font-size: 20px;
  line-height: 26px;
  color: white;
}

.tableImage {
  position: absolute;
  bottom: 0;

  width: calc(71vw - 53px);
  height: 40vh;

  background-image: url("../../assets/images/table.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.signInForm {
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translate(50%, -50%);

  display: flex;
  flex-direction: column;
  gap: 48px;
  align-items: center;

  width: 642px;
  padding: 48px;

  background: white;
  border: 3px solid black;
  border-radius: 40px;

  form {
    width: 100%;
  }
}

.signInFormWithError {

}

.confirmationLink {
  height: 593px;
  padding: 80px 64px 64px;
}

.confirmationLinkWaiting {
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirmationWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 549px;
  height: 324px;

  text-align: center;
}

.linkHeading {
  margin-bottom: 32px;
}

.resendLink {
  width: 266px;
  margin: 0 auto 32px;

  a {
    font-family:
      "Chakra Petch",
      Arial,
      Helvetica,
      sans-serif;
    color: variables.$secondary-text;
    text-decoration: underline;
  }
}

.signInFormTitle {
  font-family: "Space Grotesk";
  font-size: 47px;
  font-weight: 700;
  line-height: 59.97px;
  color: black;
  text-align: center;
}

.signInFormSubtitle {
  display: flex;
  gap: 8px;
  justify-content: center;
}

.controlsWrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;

  width: 100%;
}

.inputsGroup {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.buttonSubmit {
  height: 48px;

  background-color: black;

  &:hover {
    background-color: black;
  }
}

.forgotPassword {
  margin-top: 8px;
  margin-bottom: 32px;

  text-align: center;

  span {
    text-decoration: underline;
  }
}

.signInError {
  margin-bottom: 32px;
  padding: 8px 16px;

  background: rgb(248 205 205 / 0.1);
  border: 1px solid #ffc9c9;
  border-radius: 7px;

  button {
    font-family: "Space Grotesk";
    font-size: 12px;
    font-weight: 400;
    color: variables.$dark-text;
    text-decoration: underline;
  }
}

.inputWrapper {}

.signInPassword {}

.inputLabel {
  margin-bottom: 8px;
}

.input {
  input {
    height: 56px !important;

    background-color: variables.$light-bg;
  }
}

.signUpForm {
  top: 68px;
  transform: translate(50%, 0);

  height: calc(100vh - 68px);
  padding: 0;

  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.signUpFormInner {
  padding: 64px 55px;
}

.signUpFormSubtitle {
  margin-bottom: 64px;
}

.nameInputs {
  display: flex;
  gap: 24px;

  & > div {
    flex: 50%;
  }
}

.checkboxesWrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 20px;

  margin-bottom: 32px;
}

.gdprDummy {
  position: absolute;
  top: -3px;
  left: 94px;

  width: 51px;
  height: 20px;
}

.tacDummy {
  position: absolute;
  top: 34px;
  left: 94px;

  width: 170px;
  height: 20px;
}

.passwordWrapper {
  margin-bottom: 32px;
}

.signInPasswordWithError {
  margin-bottom: 84px;
}

.labelText {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;

  button {
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    color: variables.$dark-text;
    text-decoration: underline;
  }
}

.closeGdpr path {
  stroke: variables.$dark-text;
}

.updatePasswordWrapper {
  height: 629px;

  .inputWrapper:last-of-type {
    margin-bottom: 98px;
  }

  .inputWrapperError {
    margin-bottom: 32px;
  }
}

.forgotPassword {
  color: variables.$secondary-text;

  span {
    cursor: pointer;
  }
}

.forgotPasswordWrapper {
  height: 593px;
  padding: 64px;

  text-align: center;

  .inputWrapper {
    margin-bottom: 98px;
  }

  .inputWrapperError {
    margin-bottom: 31px;
  }
}

.backToLogin {
  margin-top: 24px;

  font-family:
    "Chakra Petch",
    Arial,
    Helvetica,
    sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 18px;
  color: variables.$dark-text;
  text-decoration: underline;

  /* 150% */
}

body:global(.dark) {
  .backToLogin {
    color: variables.$secondary-text;
  }

  .rSide {
    background-color: variables.$background;
  }

  .signInForm {
    background-color: #18181a;
    border: 3px solid #05f;
  }

  .signInFormTitle {
    color: white;
  }

  .buttonSubmit {
    background-color: #0045ce;

    &:hover {
      background-color: #0045ce;
    }
  }

  .signInFormSubtitleText {
    color: variables.$default-text;
  }

  .signUp {
    color: variables.$middle-purple;
  }

  .labelText {
    button {
      color: variables.$default-text;
    }
  }

  .closeGdpr path {
    stroke: variables.$light-accent;
  }
}
