@use "../../styles/variables";

.customViewAccountTitle {
  position: sticky;
  z-index: 15;
  top: 0;
  left: 0;

  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;

  width: 320px;
  height: 37px;
  margin-bottom: 8px;
  padding: 4px 16px;

  white-space: nowrap;

  background-color: variables.$light-purple;
}

.text {
  font-size: 18px;
}

body:global(.dark) {
  .customViewAccountTitle {
    background-color: variables.$purple;
  }
}
