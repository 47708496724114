@use "../../styles/variables";

.themeSwitcher {
  position: relative;

  width: 55px;
  height: 38px;

  background-color: variables.$light-accent;
  border-radius: 8px;

  transition: background-color 0.3s;

  &::before {
    content: "";

    position: absolute;
    top: 4px;
    left: calc(100% - 26px);

    display: block;

    width: 24px;
    height: 30px;

    background-color: variables.$purple;
    border-radius: 5px;

    transition: left 0.3s, background-color 0.3s;
  }

  .moon path {
    fill: variables.$light-blue-text;
  }

  &.animated {
    .moonWrapper {
      animation-name: moon-animation;
      animation-duration: 0.3s;
    }

    .sunWrapper {
      animation-name: sun-animation;
      animation-duration: 0.3s;
    }
  }

  &.darkTheme {
    background-color: variables.$ui-elements;

    &::before {
      left: 4px;
    }

    .moon path {
      fill: variables.$middle-purple;
      stroke: variables.$middle-purple;
    }

    .sun path {
      fill: variables.$secondary-text;
    }
  }
}

.moon,
.sun {
  path {
    transition: fill 0.3s, stroke 0.3s;
  }
}

.iconWrapper {
  position: absolute;
  top: 4px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 30px;

  transition: left 0.3s, opacity 0.3s;
}

.sunWrapper {
  left: calc(100% - 26px);
}

.moonWrapper {
  left: 4px;
}

@keyframes moon-animation {
  0% {
    left: 4px;
  }

  25% {
    opacity: 0;
  }

  50% {
    left: calc(100% - 26px);

    opacity: 0;
  }

  75% {
    opacity: 0;
  }

  100% {
    left: 4px;

    opacity: 1;
  }
}

@keyframes sun-animation {
  0% {
    left: calc(100% - 26px);
  }

  15% {
    opacity: 0;
  }

  50% {
    left: 4px;
  }

  75% {
    opacity: 0;
  }

  100% {
    left: calc(100% - 26px);

    opacity: 1;
  }
}
