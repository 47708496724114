@use "../../styles/variables";

.sideMenu {
  position: fixed;
  top: 0;
  left: 0;

  width: 316px;
  height: 100vh;
  padding: 174px 16px 0;

  background-color: variables.$white;

  transition: width 0.3s, padding 0.3s;
}

.isCollapsed {
  width: 92px;
  padding-right: 10px;
  padding-left: 10px;

  .logo {
    top: 16px;
    left: 16px;

    width: 66px;
  }
}

.logo {
  position: absolute;
  top: 40px;
  left: 42px;

  width: 208px;

  transition: width 0.3s, top 0.3s, left 0.3s;

  path {
    fill: variables.$dark-text;
  }
}

.collapseSidebarButton {
  position: absolute;
  top: 60px;
  right: -28px;
}

body:global(.dark) {
  .sideMenu {
    background-color: variables.$panels-bg;
  }

  .logo {
    path {
      fill: variables.$default-text;
    }
  }
}
