@use "../../styles/variables";

.radioButton {
  display: flex;
  gap: 8px;
  align-items: center;
}

.circle {
  position: relative;

  width: 16px;
  height: 16px;

  background-color: variables.$light-bg;
  border: 1px solid variables.$light-purple;
  border-radius: 50%;
}

.isActive {
  .circle {
    &::after {
      content: "";

      position: absolute;
      top: 2px;
      left: 2px;

      display: block;

      width: 10px;
      height: 10px;

      background-color: variables.$light-purple;
      border-radius: 50%;
    }
  }
}

body:global(.dark) {
  .circle {
    background-color: variables.$panels-bg;
    border-color: variables.$hover;
  }

  .isActive {
    .circle {
      border-color: variables.$purple;

      &::after {
        background-color: variables.$purple;
      }
    }
  }
}
