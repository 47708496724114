@use "../../styles/variables";

.collapseSidebarButton {
  width: 56px;
  height: 56px;

  &:hover {
    .circle {
      background-color: variables.$middle-purple;
    }
  }

  &:active {
    .circle {
      background-color: variables.$dark-purple;
    }
  }

  &.isCollapsed {
    .circle {
      transform: rotate(0deg);
    }
  }
}

.neckline {
  position: absolute;
  top: -15px;
  left: 0;

  path {
    fill: variables.$light-bg;
  }
}

.circle {
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(180deg);

  width: 56px;
  height: 56px;

  background-color: variables.$purple;
  background-image: url("../../assets/images/arrowClosed.svg");
  background-repeat: no-repeat;
  background-position: 18px center;
  border: 8px solid variables.$light-bg;
  border-radius: 50%;

  transition: background-color 0.1s;
}

body:global(.dark) {
  .circle {
    border-color: variables.$background;
  }

  .neckline {
    path {
      fill: variables.$background;
    }
  }
}
