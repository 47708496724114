@use "../../styles/variables";

.proViewSwitcher {
  cursor: pointer;

  position: relative;

  display: flex;
  justify-content: space-between;

  width: 130px;
  height: 40px;
  padding: 4px;

  background-color: variables.$light-hover;
  border: 1px solid variables.$light-accent;
  border-radius: 10px;

  transition: background-color 0.1s;

  &:hover {
    background-color: variables.$light-accent;

    .background {
      background-color: variables.$middle-purple;
    }
  }
}

.background {
  height: 32px;

  background-color: variables.$purple;
  border-radius: 7px;

  transition: left 0.1s, width 0.1s, background-color 0.1s;

  &.standartActive {
    position: absolute;
    top: 3px;
    left: 3px;

    width: 59px;
  }

  &.proActive {
    position: absolute;
    top: 3px;
    left: 63px;

    width: 62px;
  }
}

.modeWrapper {
  position: relative;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 30px;

  &.standart {
    width: 55px;
  }

  &.pro {
    width: 58px;
  }
}

.mode {
  font-size: 16px;
  font-weight: 400;
  color: variables.$purple;

  transition: color 0.1s;

  &.modeActive {
    font-weight: 500;
    color: variables.$white;
  }
}

.disabled {
  cursor: not-allowed;

  .background {
    background-color: variables.$light-purple;
  }

  &:hover {
    background-color: variables.$light-hover;
  }
}

body:global(.dark) {
  .proViewSwitcher {
    background-color: variables.$ui-elements;
    border-color: variables.$hover;

    &:hover {
      background-color: variables.$hover;

      .background {
        background-color: variables.$middle-purple;
      }
    }
  }

  .mode {
    color: variables.$default-text;

    &.modeActive {
      color: variables.$header-text;
    }
  }

  .disabled {
    .background {
      background-color: variables.$hover;
    }
  }
}
