@use "../../../../styles/variables";

.newAccountScreen {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 716px;
  padding: 108px 338px 42px;
}

.description {
  margin-bottom: 100px;

  font-size: 16px;
  line-height: 21px;
  text-align: center;
}

.inputLabel {
  margin-bottom: 10px;

  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.input {
  width: 459px;
  height: 56px !important;
  margin-bottom: 10px;
  padding: 16px !important;

  font-size: 16px;
  line-height: 20px;

  background: #f0f4ff;
  border-radius: 10px;
}

.inputDescription {
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
}

.buttonsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.createButton {
  width: 294px;
  height: 48px;
  margin-bottom: 25px;
}

.cancelButton {
  font-family: "Space Grotesk", Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.successIcon {
  margin-top: 96px;
  margin-bottom: 56px;
}

.newAccountName {
  margin-bottom: 8px;

  font-size: 22px;
  font-weight: 600;
  line-height: 29px;
  color: variables.$purple;
}

.successNote {
  font-size: 22px;
  font-weight: 400;
  line-height: 29px;
}

.successTopWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

body:global(.dark) {
  .cancelButton {
    color: variables.$default-text;
  }

  .newAccountName {
    color: variables.$middle-purple;
  }
}
