@use "../../styles/variables";

.dropdown {
  position: relative;

  width: 189px;
  height: 32px;
}

.dropdownHeader {
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 32px;
  padding: 6px 12px;

  background-color: variables.$light-hover;
  border-radius: 8px;

  transition: background-color 0.1s;

  &:hover {
    background-color: variables.$light-accent;
  }
}

.dropdownHeaderOpen {
  background-color: variables.$light-accent;
}

.arrowDown {
  transform: rotate(180deg);

  flex-shrink: 0;
}

.arrowUp {
  transform: rotate(0deg);

  flex-shrink: 0;
}

.dropdownList {
  position: absolute;
  z-index: 100;
  top: calc(100% + 8px);
  left: 0;

  width: 100%;
  padding-top: 6px;
  padding-bottom: 6px;

  background-color: variables.$white;
  border: 1px solid variables.$light-accent;
  border-radius: 5px;
  box-shadow: 0 4px 10px rgb(68 61 255 / 0.05);

  transition: opacity 0.3s ease-in-out;

  &.hidden {
    pointer-events: none;

    opacity: 0;
  }
}

.limitedDropdownList {

}

.dropdownListItem {
  cursor: pointer;

  height: 32px;
  padding: 6px 16px;

  &:hover:not(.empty) {
    background-color: #f0f4ff;
  }

  &.selected:not(.empty) {
    background-color: variables.$light-accent;
  }
}

.labelText {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: variables.$purple;
}

.optionText {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.disabled {
  cursor: not-allowed;

  opacity: 0.7;
}

.empty {
  cursor: default;
}

.textInput {
  position: relative;
  left: -10px;

  input {
    background-color: transparent !important;
    border-color: transparent !important;
  }
}

.disabledTitle {
  opacity: 0.5;
}

body:global(.dark) {
  .dropdownHeader {
    background-color: variables.$ui-elements;

    &:hover {
      background-color: variables.$hover;
    }
  }

  .dropdownHeaderOpen {
    background-color: variables.$accent;
  }

  .dropdownList {
    background-color: variables.$panels-bg;
    border-color: variables.$hover;
  }

  .dropdownListItem {
    &:hover:not(.empty) {
      background-color: variables.$purple;
    }

    &.selected:not(.empty) {
      background-color: variables.$middle-purple;
    }
  }

  .arrowUp {
    path {
      stroke: variables.$white;
    }
  }
}
