@use "../../styles/variables";

.rightSection {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 637px;
  padding: 24px;
}

.tabsWrapperApi {
  margin-bottom: 24px;
}

.tab {
  position: relative;

  padding-bottom: 6px;

  font-family: "Space Grotesk", Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  line-height: 20px;
  color: variables.$dark-text;
  text-transform: uppercase;

  &.tabActive {
    color: variables.$purple;

    &::after {
      content: "";

      position: absolute;
      bottom: 0;
      left: 0;

      display: block;

      width: 100%;
      height: 2px;

      background-color: variables.$middle-purple;
    }
  }
}

.requestAccess {
  width: 166px;
  margin-top: 24px;
  margin-bottom: 24px;
}

.apiDocumentationWrapper {
  position: absolute;
  bottom: 24px;

  margin-bottom: 0;
}

.apiDocumentationHeading {
  margin-bottom: 4px;

  line-height: 20px;
}

.apiDocumentationDescription {
  font-size: 14px;
  line-height: 14px;
}

.apiLink {
  color: variables.$purple;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.sentRequest {
  display: flex;
  gap: 8px;
  align-items: center;

  margin-bottom: 24px;
}

.generateKey {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 12px;
}

.generateKeyBtn {
  width: 178px;
}

.apiTable {
  overflow: hidden;

  width: 100%;
  margin-bottom: 16px;

  border: 1px solid variables.$exchange-row;
  border-radius: 10px;

  tr {
    &:last-of-type {
      td {
        border-bottom: none;
      }
    }
  }

  th {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;

    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: variables.$purple;
    text-align: left;

    background-color: variables.$exchange-row;

    &:first-of-type {
      width: 173px;
      padding-left: 16px;
    }

    &:last-of-type {
      width: 40px;
    }
  }

  td {
    padding-top: 4px;
    padding-bottom: 4px;

    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: variables.$dark-text;

    border-bottom: 1px solid variables.$exchange-row;

    &:first-of-type {
      padding-left: 8px;
    }

    &:last-of-type {
      padding: 0;
    }
  }
}

.deleteTableRow {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  svg {
    path {
      stroke: variables.$light-purple;

      transition: stroke 0.3s;
    }
  }

  &:hover {
    svg {
      path {
        stroke: variables.$purple;
      }
    }
  }

  &:focus {
    svg {
      path {
        stroke: variables.$purple;
      }
    }
  }
}

.copyable {
  cursor: url("../../assets/images/cursorcopy.png"), auto;

  display: inline-flex;

  padding: 4px 8px;

  border-radius: 10px;

  &:hover {
    background-color: variables.$light-hover;
  }
}

.deleteConfirmation {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background: rgb(variables.$white / 0.5);
}

.deleteContent {
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
  padding: 24px;

  background-color: variables.$light-hover;
  border-top: 2px solid variables.$purple;
}

.deleteTitle {
  margin-bottom: 16px;

  color: variables.$purple;
  text-transform: uppercase;
}

.deleteTextAndButtons {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.buttonsWrapper {
  display: flex;
  gap: 8px;
}

.deleteBtnYes {
  width: 88px;
}

.deleteBtnNo {
  width: 88px;
}

body:global(.dark) {
  .tab {
    color: variables.$secondary-text;

    &.tabActive {
      color: variables.$default-text;
    }
  }

  .apiLink {
    color: variables.$middle-purple;
  }

  .apiTable {
    th {
      color: variables.$secondary-text;
    }

    td {
      color: variables.$default-text;
    }
  }

  .copyable {
    &:hover {
      background-color: variables.$ui-elements;
    }
  }

  .deleteConfirmation {
    background-color: #0006;
  }

  .deleteContent {
    background-color: variables.$panels-bg;
    border-top: 2px solid variables.$middle-purple;
  }

  .deleteTitle {
    color: variables.$middle-purple;
  }

  .deleteTableRow {
    svg {
      path {
        stroke: #a3a5b2;

        transition: stroke 0.3s;
      }
    }

    &:hover {
      svg {
        path {
          stroke: variables.$middle-purple;
        }
      }
    }

    &:focus {
      svg {
        path {
          stroke: variables.$purple;
        }
      }
    }
  }
}
