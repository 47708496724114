@use "../../styles/variables";

.filtersTopSection {
  display: flex;
  justify-content: space-between;

  padding: 0 16px;

  background-color: variables.$light-accent;
  border: 1px solid variables.$light-hover;
  border-bottom: 0;
}

.tabWrapper {
  display: flex;
  gap: 21px;
  align-items: center;

  height: 32px;
}

.usdTotal {
  display: flex;
  gap: 21px;
  align-items: center;

  height: 32px;
}

.cardTotalHeading {
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  text-transform: uppercase;
}

.cardTotalSum {
  font-size: 16px;
  line-height: 21px;
  text-transform: uppercase;
}

.cardTotalTimestamp {
  font-size: 14px;
  line-height: 18px;
  color: variables.$secondary-text;
}

body:global(.dark) {
  .filtersTopSection {
    background-color: variables.$ui-elements;
    border-color: variables.$accent;
  }

  .cardTotalTimestamp {
    color: #9fa0ae;
  }
}
