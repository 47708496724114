@use "../../styles/variables";

.datepicker {
  :global(.react-datepicker) {
    font-family: "Space Grotesk";

    border-color: variables.$light-purple;
  }

  :global(.react-datepicker__triangle) {
    display: none;
  }

  :global(.react-datepicker__current-month) {
    color: variables.$purple;
  }

  :global(.react-datepicker__header) {
    background-color: variables.$light-accent;
    border-color: variables.$light-purple;
  }

  :global(.react-datepicker-time__header) {
    color: variables.$purple;
  }

  :global(.react-datepicker__day--selected,) {
    color: #fff;

    background-color: variables.$purple;
    border-radius: 4px;
  }

  :global(.react-datepicker__day--selected:hover,) {
    background-color: variables.$middle-purple;
  }

  :global(.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected) {
    font-weight: 400;

    background-color: variables.$purple;
  }

  :global(.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover) {
    background-color: variables.$middle-purple;
  }

  :global(.react-datepicker__day--today,) {
    font-weight: 400;
  }

  :global(.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item) {
    padding: 6px 10px;
  }

  :global(.react-datepicker__time-container) {
    border-color: variables.$light-purple;
  }

  :global(.react-datepicker__navigation-icon::before) {
    border-color: #7297ff;
  }

  :global(.react-datepicker__navigation:hover *::before) {
    border-color: variables.$purple;
  }

  .datepickerInput {
    font-weight: 500;
    color: #363853;

    background-color: #f0f4ff;
    border: 1px solid transparent;

    &:hover,
    &:focus,
    &:active {
      background-color: #f0f4ff;
      border-color: #7297ff;
    }
  }
}

.disabled {
  .datepickerInput {
    cursor: not-allowed;

    opacity: 0.7;

    &:hover,
    &:focus,
    &:active {
      border-color: transparent;
    }
  }
}

.disabledTitle {
  opacity: 0.5;
}

body:global(.dark) {
  .datepicker {
    .datepickerInput {
      color: var(--default-text);

      background-color: var(--ui-elements);
      border-color: transparent;

      &:hover {
        background-color: var(--hover);
      }

      &:focus {
        background-color: var(--ui-elements);
        border-color: var(--purple);
      }
    }
  }

  .disabled {
    .datepickerInput:hover {
      background-color: var(--ui-elements);
    }
  }

  :global(.react-datepicker) {
    background-color: variables.$background;
    border-color: variables.$hover;
  }

  :global(.react-datepicker__triangle) {
    display: none;
  }

  :global(.react-datepicker__current-month) {
    color: variables.$white;
  }

  :global(.react-datepicker__header) {
    background-color: variables.$panels-bg;
    border-color: variables.$hover;
  }

  :global(.react-datepicker__time) {
    background-color: variables.$background;
  }

  :global(.react-datepicker-time__header) {
    color: variables.$white;

    border-color: variables.$hover;
  }

  :global(.react-datepicker__day-name) {
    color: variables.$white;
  }

  :global(.react-datepicker__day--selected,) {
    color: #fff;

    background-color: variables.$purple;
    border-radius: 4px;
  }

  :global(.react-datepicker__day--selected:hover,) {
    background-color: variables.$middle-purple;
  }

  :global(.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected) {
    font-weight: 400;

    background-color: variables.$purple;
  }

  :global(.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover) {
    background-color: variables.$middle-purple;
  }

  :global(.react-datepicker__day--today,) {
    font-weight: 400;
  }

  :global(.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item) {
    padding: 6px 10px;

    color: variables.$white;

    &:hover {
      background-color: variables.$hover;
    }
  }

  :global(.react-datepicker__time-container) {
    border-color: variables.$hover;
  }

  :global(.react-datepicker__navigation-icon::before) {
    border-color: #7297ff;
  }

  :global(.react-datepicker__navigation:hover *::before) {
    border-color: variables.$purple;
  }

  :global(.react-datepicker__day) {
    color: variables.$white;
  }

  :global(.react-datepicker__day:hover) {
    background-color: variables.$hover;
  }
}
