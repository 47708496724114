@use "../../../../styles/variables";

.tableRow {
  cursor: pointer;

  position: relative;

  display: flex;

  &:hover > div {
    background-color: variables.$portfolio-row;
  }

  &.empty {
    cursor: default;
  }
}

.fitContent {
  width: fit-content;
}

.isCollapsed {
  height: 0;

  visibility: collapse;
}

.defaultCursor {
  cursor: default;
}

.withSpacing {
  &::after {
    content: "";

    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;

    display: block;

    width: 100%;
    height: 2px;

    background-color: variables.$portfolio-row;
  }

  &:first-child {
    &::after {
      display: none;
    }
  }
}

body:global(.dark) {
  .tableRow {
    &:hover > div {
      background-color: variables.$hover;
    }
  }
}
