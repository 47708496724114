@use "../../styles/variables";

.checkbox {
  box-sizing: border-box;
  width: 16px;
  height: 16px;
}

.wideLabel {
  width: auto;
}

.hiddenInput {
  display: none;
}

.label {
  cursor: pointer;

  display: flex;
  gap: 8px;
}

.tickmark {
  position: relative;

  flex-shrink: 0;

  width: 16px;
  height: 16px;

  background-color: #f0f4ff;
  border: 1px solid transparent;
  border-radius: 2px;

  transition: background-color 0.1s, border-color 0.1s;
}

.label:hover {
  .tickmark {
    background-color: variables.$light-hover;
  }
}

.hiddenInput:checked {
  & + .label {
    .tickmark {
      background-image: url("../../assets/images/checkboxModal.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 12px 9px;
      border-color: variables.$light-purple;
    }
  }
}

.disabled {
  filter: grayscale(1);

  .labelText {
    color: rgb(54 56 83 / 0.7);
  }
}

.labelText {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
}

body:global(.dark) {
  .tickmark {
    background-color: variables.$panels-bg;
    border-color: variables.$hover;
  }

  .label:hover {
    .tickmark {
      background-color: variables.$ui-elements;
    }
  }

  .hiddenInput:checked {
    & + .label {
      .tickmark {
        background-image: url("../../assets/images/checkboxModalDarkTheme.svg");
        border-color: variables.$middle-purple;
      }
    }
  }
}
