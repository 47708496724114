/* Colors */

$dark-purple:var(--dark-purple);
$middle-purple: var(--middle-purple);
$purple: var(--purple);
$light-purple: var(--light-purple);
$blue-text: var(--blue-text);
$light-blue-text: var(--light-blue-text);
$light-accent: var(--light-accent);
$light-hover: var(--light-hover);
$light-bg:  var(--light-bg);
$exchange-row: var(--exchange-row);
$portfolio-row: var(--portfolio-row);
$table-header: var(--table-header);
$orange: var(--orange);
$light-orange: var(--light-orange);
$red: var(--red);
$middle-red: var(--middle-red);
$light-red: var(--light-red);
$green: var(--green);
$light-green: var(--light-green);
$black: var(--black);
$dark-text: var(--dark-text);
$grey: var(--grey);
$secondary-text:  var(--secondary-text);
$white: var(--white);

/* Dark theme specific variables */

$background: var(--background);
$panels-bg: var(--panels-bg);
$ui-elements: var(--ui-elements);
$hover: var(--hover);
$accent: var(--accent);
$default-text: var(--default-text);
$header-text: var(--header-text);

/* Spacing */

$space-unit: 2px;
$space-unit-2: $space-unit * 2;
$space-unit-3: $space-unit * 3;
$space-unit-6: $space-unit * 6;
$space-unit-8: $space-unit * 8;
$space-unit-12: $space-unit * 12;
