@use "../../../../styles/variables";

.newAccountScreenHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 22px 24px;

  background-color: variables.$purple;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.setupWrapper {}

.setup {
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  letter-spacing: -0.03em;
}

.stepWrapper {
  p {
    text-align: right;
  }
}

.step1 {
  margin-bottom: 8px;

  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
  color: variables.$light-hover;
  text-transform: uppercase;
}

.accountCreation {
  font-size: 22px;
  font-weight: 600;
  line-height: 29px;
  color: variables.$white;
  text-transform: uppercase;
}
