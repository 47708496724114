@use "../../styles/variables";

.backdrop {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

.kebab {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;

  border-radius: 5px;

  transition: background-color 0.1s;

  &:hover {
    background-color: variables.$light-bg;
  }

  &.active {
    background-color: variables.$light-bg;
  }
}

.menu {
  position: absolute;
  z-index: 110;
  top: 32px;
  left: 0;

  padding: 4px 0;

  white-space: nowrap;

  background-color: variables.$white;
  border: 1px solid variables.$light-hover;
  border-radius: 5px;
  box-shadow: 0 4px 10px rgb(68 61 255 / 0.05);
}

.menuRight {
  right: 0;
  left: auto;
}

.menuRightBottom {
  top: auto;
  right: 0;
  bottom: 32px;
  left: auto;
}

.menuItem {
  cursor: pointer;

  padding-top: 4px;
  padding-right: 24px;
  padding-bottom: 4px;
  padding-left: 12px;

  text-align: left;

  transition: background-color 0.1s;

  &:hover {
    background-color: variables.$light-bg;
  }
}

body:global(.dark) {
  .kebab {
    &:hover {
      background-color: variables.$ui-elements;
    }

    &.active {
      background-color: variables.$purple;
    }
  }

  .kebabIcon path {
    fill: variables.$default-text;
  }

  .menu {
    background-color: variables.$panels-bg;
    border-color: variables.$hover;
    box-shadow: 0 4px 20px rgb(0 0 0 / 0.2);
  }

  .menuItem {
    &:hover {
      background-color: variables.$purple;
    }
  }
}
