@use "../../../../styles/variables";

.title {
  margin-right: 16px;

  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.03em;
}

.topWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topLeftWrapper {
  display: flex;
  align-items: center;

  padding: 12px 16px;
}

.topRightWrapper {
  display: flex;
  gap: 8px;
}

.statements {
  height: 56px;

  border-radius: 0;

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 18.2px;
    letter-spacing: -0.03em;
  }
}

.legendaWrapper {
  display: flex;
  gap: 16px;
  align-items: center;

  margin-left: 24px;
}

.report {
  top: 150px;
  right: 8px;
}

body:global(.dark) {
  .customViewTitle {
    color: variables.$middle-purple;
  }
}
