@use "../../styles/variables";

.emptyMyEnvironmentScreen {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 540px;
}

.text {
  color: variables.$blue-text;
  text-align: center;
}
