@use "../../styles/variables";

.noScroll {
  overflow-x: hidden;
  overflow-y: hidden;

  max-height: 100%;
}

.backdrop {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  visibility: hidden;
  opacity: 0;
  background-color: rgb(14 14 97 / 0.3);

  transition: visibility 0.3s, opacity 0.3s;

  &.open {
    visibility: visible;
    opacity: 1;

    .modal {
      top: 0;
    }
  }
}

.modal {
  position: relative;
  top: 15px;

  width: 1202px;
  height: 640px;

  background: variables.$white;
  border-radius: 10px;
  box-shadow: 0 4px 30px rgb(0 0 0 / 0.13);

  transition: top 0.3s ease;

  .modalHeader {
    position: relative;

    height: 64px;
    padding: 22px 24px;

    text-transform: uppercase;

    background-color: variables.$purple;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .modalTitle {
    font-size: 29px;
    font-weight: 700;
    color: variables.$white;
  }

  .close {
    position: absolute;
    top: 16px;
    right: 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 32px;
    height: 32px;

    path {
      stroke: variables.$light-accent;

      transition: 0.1s stroke-width, 0.1s stroke;
    }

    &:hover {
      path {
        stroke: variables.$white;
        stroke-width: 3px;
      }
    }
  }
}

body:global(.dark) {
  .backdrop {
    background-color: rgb(0 0 0 / 0.6);
  }

  .modal {
    background-color: variables.$background;
  }

  .modalTitle {
    color: variables.$header-text;
  }

  .close {
    path {
      stroke: variables.$default-text;
    }

    &:hover {
      path {
        stroke: variables.$header-text;
      }
    }
  }
}

body:global(.dark) {
  .backdrop {
    background-color: rgb(0 0 0 / 0.6);
  }

  .modal {
    background-color: variables.$background;
  }

  .modalTitle {
    color: variables.$header-text;
  }

  .close {
    path {
      stroke: variables.$default-text;
    }
  }
}
