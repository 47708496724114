@use "../../styles/variables";

.customViewsButton {
  display: flex;
  gap: 12px;
  align-items: center;

  width: 100%;
  height: 54px;
  padding: 12px 61px 12px 24px;

  white-space: nowrap;

  border-radius: 10px;

  transition:
    background-color 0.1s,
    opacity 0.1s,
    visibility 0.1s;

  &:hover {
    background-color: variables.$light-bg;

    .arrow path {
      stroke: variables.$purple;
    }
  }

  svg path {
    transition: fill 0.1s, stroke 0.1s;
  }
}

.text {
  font-size: 22px;
  font-weight: 500;

  transition: color 0.1s;
}

.arrow {
  flex-shrink: 0;

  transition: transform 0.3s;

  path {
    transition: stroke 0.1s;
  }
}

.rotatedArrow {
  transform: rotate(-90deg);
  transform-origin: center;
}

.childrenWrapper {
  height: 0;

  opacity: 0;

  transition: opacity 0.3s, height 0.3s;
}

.isExpandedWrapper {
  height: auto;

  opacity: 1;
}

.isCollapsed {
  display: none;

  visibility: hidden;
  opacity: 0;
}

.customViewIcon {
  flex-shrink: 0;
}

.isDisabled {
  cursor: default;

  &:hover {
    background-color: transparent;
  }

  .customViewIcon path {
    stroke: variables.$secondary-text;
  }

  .text {
    color: variables.$secondary-text;
  }
}

body:global(.dark) {
  .customViewsButton {
    &:hover {
      background-color: variables.$ui-elements;

      .arrow path {
        stroke: variables.$default-text;
      }
    }

    &:active {
      background-color: variables.$hover;
    }
  }

  .isDisabled {
    .text {
      color: variables.$secondary-text;
    }

    &:hover {
      background-color: transparent;
    }

    .customViewIcon path {
      stroke: variables.$secondary-text;
    }
  }

  .arrow path {
    stroke: variables.$default-text;
  }

  .customViewIcon path {
    stroke: variables.$default-text;
  }
}
