@use "../../../../styles/variables";

.newAccountScreen {
  .dropdownWrapper {
    align-items: center;

    width: 166px;

    h3 {
      margin-bottom: 8px;

      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: -0.03em;
    }
  }

  input[type="text"] {
    color: variables.$purple;

    background-color: variables.$light-hover;
    border-radius: 8px;
  }
}

.successBtmWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
}

.screenDescription {
  width: 1070px;
  margin-bottom: 16px;
}

.inputTitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.03em;
}

.chooseExchangeWrapperHeading.disabled {
  opacity: 0.5;
}

.inputsColumns {
  display: flex;
  gap: 16px;

  width: 1070px;
}

.inputsColumnsWrapper {
  display: flex;
  flex-direction: column;

  background-color: variables.$light-bg;
  border-radius: 10px;
}

.inputsLine {
  padding: 15px 16px;

  border-bottom: 1px solid variables.$purple;
  border-radius: 8px 0 0;

  &:last-of-type {
    border-bottom: none;
  }
}

.dropdownWrapper2 {
  display: flex;
  gap: 24px;
  align-items: center;

  width: 348px;
  margin: 0 auto 10px;
  margin-bottom: 16px;

  h3 {
    margin-bottom: 8px;

    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: -0.03em;
  }
}

.newAccountScreen {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 792px;
  height: 568px;
  padding: 16px 24px 24px;

  &.successScreen {
    height: 640px;
  }
}

.underlyingAsset {
  display: flex;
  gap: 12px;
  align-items: center;

  padding-top: 20px;
}

.description {
  margin-bottom: 100px;

  font-size: 16px;
  line-height: 21px;
  text-align: center;
}

.inputLabel {
  margin-bottom: 10px;

  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.input {
  width: 100%;
  height: 56px;
  height: 32px !important;
  padding: 16px;
  padding: 6px 12px !important;

  font-size: 16px;
  line-height: 20px;

  background: #f0f4ff;
  border-radius: 10px;

  //margin-bottom: 10px;
}

.inputDescription {
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
}

.dropdown {
  width: 100%;
}

.instrumentName {
  width: 100%;

  input {
    border-radius: 8px;
  }

  h3 {
    margin-bottom: 8px;

    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: -0.03em;
  }
}

.buttonsWrapper {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: flex-end;
}

.createButton {
  width: 88px;
  height: 40px;
}

.loadingIndicatorWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
}

.createButtonTwo {
  width: 294px;
  height: 48px;
}

.successIcon {
  margin-top: 125px;
  margin-bottom: 56px;
}

.newAccountName {
  margin-bottom: 8px;

  font-size: 22px;
  font-weight: 600;
  line-height: 29px;
  color: variables.$purple;
}

.successNote {
  font-size: 22px;
  font-weight: 400;
  line-height: 29px;
}

.successTopWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.firstLineWrapper {
  display: flex;
  gap: 16px;
}

:global {
  .react-datepicker {
    font-family: "Space Grotesk";
  }

  .react-datepicker__day--keyboard-selected {
    color: variables.$white;

    background-color: variables.$middle-purple;

    &:hover {
      color: variables.$white;

      background-color: variables.$middle-purple;
    }
  }

  .react-datepicker-wrapper {
    input {
      width: 100%;
      height: 32px;
      padding: 2px 8px;

      font-family: "Space Grotesk";
      font-size: 16px;
      color: var(--default-text);

      background-color: var(--ui-elements);
      border-color: transparent;
      border-radius: 5px;

      &:hover {
        background-color: variables.$hover;
        border-color: transparent;
      }

      &:focus {
        background-color: variables.$ui-elements;
        border-color: variables.$purple;
        outline: none;
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        box-shadow: 0 0 0 30px variables.$ui-elements inset !important;
      }
    }
  }
}

body:global(.dark) {
  .newAccountName {
    color: variables.$middle-purple;
  }

  .inputsColumnsWrapper {
    background-color: #262629b2;
  }

  .newAccountScreen {
    input[type="text"] {
      color: variables.$white;

      background-color: variables.$ui-elements;
      border-radius: 8px;
    }
  }
}
