@use "../../styles/variables";

.termsAndConditions {
  position: fixed;
  top: 0;
  right: 0;

  width: 815px;
  height: 100vh;
  padding: 72px 96px;

  background-color: white;

  p {
    margin-bottom: 16px;
  }

  a {
    color: variables.$purple;
    text-decoration: underline;
  }

  table {
    border-collapse: collapse;

    margin-bottom: 16px;

    td {
      padding: 8px;

      line-height: 20px;
      color: variables.$dark-text;

      border: 1px solid variables.$dark-text;
    }
  }

  ul {
    li {
      margin-bottom: 8px;
    }
  }
}

.termsAndConditionsInner {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 623px;
  margin-bottom: 36px;
}

.headingTC {
  font-family: "Space Grotesk";
  font-size: 29px;
  font-weight: 700;
  font-style: normal;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 0.87px;
}

body:global(.dark) {
  .termsAndConditions {
    background-color: variables.$background;

    table {
      td {
        color: variables.$default-text;

        border: 1px solid variables.$default-text;
      }
    }

    a {
      color: variables.$middle-purple;
    }
  }
}
