@use "../../styles/variables";

.header {

}

.tabs {
  height: 37px;

  background-color: variables.$table-header;
  border-bottom: 1px solid variables.$light-hover;
}

.positions {
  position: relative;

  padding: 8px 30px 8px 16px;

  background-color: variables.$light-bg;
  border-right: 1px solid variables.$light-hover;

  &::before {
    content: "";

    position: absolute;
    top: 0;
    left: 0;

    display: block;

    width: 6px;
    height: 100%;

    background-color: variables.$light-purple;
  }
}

.positionsText {
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  letter-spacing: -0.03em;
}

body:global(.dark) {
  .tabs {
    background-color: variables.$panels-bg;
    border-bottom: 1px solid variables.$hover;
  }

  .positions {
    background-color: variables.$background;
    border-right: 1px solid variables.$hover;
  }
}
