@use "../../styles/variables";

.loadingIndicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: 295px;
  height: 96px;
}

.spinner {
  animation-name: spin;
  animation-duration: 5000ms;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  path {
    stroke: variables.$dark-text;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

body:global(.dark) {
  .spinner {
    path {
      stroke: variables.$white;
    }
  }
}
