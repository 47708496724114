@use "../../../../styles/variables";

.tableCell {
  position: relative;

  flex-shrink: 0;

  width: 102px;
  height: 29px;

  background-color: variables.$white;
  border: 1px solid variables.$light-hover;
  border-top: none;
  border-right: none;

  .textWrapper {
    align-items: center;
    justify-content: flex-end;

    height: 29px;
  }

  &.empty {
    .textWrapper {
      opacity: 0.5;
    }
  }

  &:first-child {
    .textWrapper {
      justify-content: flex-start;
    }

    .withPlatform {
      justify-content: space-between;

      .text {
        font-weight: 600;
        color: variables.$purple;
      }
    }
  }

  &:nth-child(2) {
    border-left: none;
  }

  &:last-child {
    border-right: 1px solid variables.$light-hover;
  }

  &.nameCell {
    position: sticky;
    z-index: 10;
    top: 0;
    left: 0;

    width: 320px !important;

    border-right: 1px solid variables.$light-hover;
  }

  &.wide {
    width: 143px;
  }

  &.expand {
    width: calc((100% - 320px) / 3);
  }

  &:hover {
    .tooltip {
      visibility: visible;
      opacity: 1;
    }
  }
}

.text {
  font-size: 14px;
  line-height: 18px;
  white-space: nowrap;
}

.total {
  .text {
    font-weight: 700;
  }
}

.exchange {
  background-color: variables.$exchange-row;

  &:first-child {
    &::before {
      content: "";

      position: absolute;
      top: 0;
      left: 0;

      display: block;

      width: 6px;
      height: 28px;

      background-color: variables.$green;
    }

    &::after {
      content: "";

      position: absolute;
      top: 6px;

      display: block;

      width: 16px;
      height: 16px;

      background-image: url("../../../../assets/images/tableClosed.svg");
      background-repeat: no-repeat;
      background-position: center;
    }

    &.empty {
      &::after {
        transform: rotate(0deg);

        background-image: url("../../../../assets/images/tableClosedDisabled.svg");
      }
    }

    &.hasSpacing {
      &::after {
        top: 8px;
      }

      &::before {
        top: 2px;
      }
    }
  }

  &.isOpen {
    &::after {
      transform: rotate(-90deg);
    }
  }
}

.portfolio {
  background-color: variables.$portfolio-row;

  &:first-child {
    &::before {
      content: "";

      position: absolute;
      top: 0;
      left: 0;

      display: block;

      width: 6px;
      height: 28px;

      background-color: variables.$light-purple;
    }

    &::after {
      content: "";

      position: absolute;
      top: 6px;

      display: block;

      width: 16px;
      height: 16px;

      background-image: url("../../../../assets/images/tableClosed.svg");
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &.isOpen {
    &::after {
      transform: rotate(-90deg);
    }
  }

  &.empty {
    &::after {
      transform: rotate(0deg);

      background-image: url("../../../../assets/images/tableClosedDisabled.svg");
    }
  }
}

.balance_section {
  &:first-child {
    &::after {
      content: "";

      position: absolute;
      top: 6px;

      display: block;

      width: 16px;
      height: 16px;

      background-image: url("../../../../assets/images/tableClosed.svg");
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &.isOpen {
    &::after {
      transform: rotate(-90deg);
    }
  }
}

.expiry {
  background-color: variables.$light-orange;

  &:first-child {
    &::after {
      content: "";

      position: absolute;
      top: 6px;

      display: block;

      width: 16px;
      height: 16px;

      background-image: url("../../../../assets/images/tableClosed.svg");
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &.isOpen {
    &::after {
      transform: rotate(-90deg);
    }
  }
}

.balance {
  background-color: variables.$white;
}

.textWrapper {
  display: flex;
  align-items: center;

  padding: 0 12px 0 0;
}

.hasSpacing {
  height: 31px;
  padding-top: 2px;
}

.platform {
  font-size: 14px;
  line-height: 18px;
  color: variables.$secondary-text;
}

.margin {
  font-size: 12px;
  line-height: 16px;
  color: variables.$secondary-text;
}

.marginNumber {
  font-size: 12px;
  line-height: 16px;
  color: variables.$secondary-text;
  white-space: pre-wrap;
}

.yellowMargin {
  color: #cd960a;
}

.redMargin {
  color: variables.$red;
}

.level0 {
  &:first-child {
    padding-left: 44px;

    &::after {
      left: 16px;
    }
  }
}

.level1 {
  &:first-child {
    padding-left: 60px;

    &::after {
      left: 32px;
    }
  }
}

.level2 {
  &:first-child {
    padding-left: 76px;

    &::after {
      left: 48px;
    }
  }
}

.level3 {
  &:first-child {
    padding-left: 92px;

    &::after {
      left: 64px;
    }
  }
}

.level4 {
  &:first-child {
    padding-left: 108px;

    &::after {
      left: 80px;
    }
  }
}

.picture {
  width: 18px;
  height: auto;
  margin-right: 12px;
  margin-left: -30px;

  border-radius: 50%;
}

.green {
  color: variables.$green;
}

.red {
  color: variables.$red;
}

.tooltip {
  position: absolute;
  z-index: 20;
  top: calc(100% + 8px);
  right: 8px;

  padding: 8px 16px;

  visibility: hidden;
  opacity: 0;
  background-color: variables.$light-bg;
  border: 1px solid variables.$light-hover;
  border-radius: 7px;
  box-shadow: 0 4px 10px rgb(68 61 255 / 0.05);
}

.tooltipText {
  font-size: 14px;
}

.highlighted {
  background-color: #f1f7f2;
}

body:global(.dark) {
  .tableCell {
    border-color: variables.$hover;

    &:first-child {
      .withPlatform {
        .text {
          font-weight: 700;
          color: variables.$header-text;
        }
      }
    }
  }

  .exchange {
    &::after {
      background-image: url("../../../../assets/images/tableClosedDarkTheme.svg");
    }
  }

  .total {
    background-color: variables.$panels-bg;

    &:hover {
      cursor: default;
    }
  }

  .portfolio {
    background-color: variables.$panels-bg;

    &::after {
      background-image: url("../../../../assets/images/tableClosedDarkTheme.svg");
    }
  }

  .balance_section {
    background-color: variables.$panels-bg;

    &::after {
      background-image: url("../../../../assets/images/tableClosedDarkTheme.svg");
    }
  }

  .expiry {
    background-color: #222235;

    &::after {
      background-image: url("../../../../assets/images/tableClosedDarkTheme.svg");
    }
  }

  .balance {
    background-color: variables.$background;
  }

  .tooltip {
    background-color: variables.$panels-bg;
    border-color: variables.$accent;
  }

  .green {
    color: variables.$green;
  }

  .red {
    color: #ff5018;
  }

  .highlighted {
    background-color: #203434;
  }

  .yellowMargin {
    color: #d1bf02;
  }

  .redMargin {
    color: variables.$red;
  }
}
