@use "../../styles/variables";

.mobileWarning {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  height: 100vh;
  padding: 105px 40px 51px;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 48px;
  align-items: center;
}

.logo {
  margin-bottom: 47px;

  path:global(.logo-text) {
    fill: variables.$dark-text;
  }
}

.welcome {
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  color: variables.$dark-text;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}

.rms {
  color: variables.$purple;
}

.warningText {
  font-size: 16px;
  line-height: 20px;
  color: variables.$dark-text;
  text-align: center;
}

.buttonsWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
}

.goBack {
  display: flex;
  gap: 5px;
  align-items: center;

  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: variables.$purple;
  text-align: center;
}

.arrow {
  path {
    stroke: variables.$purple;
  }
}

.goToRm {
  font-size: 16px;
  line-height: 20px;
  color: #757680;
  text-align: center;
  text-decoration: underline;
}

body:global(.dark) {
  .logo {
    path:global(.logo-text) {
      fill: variables.$white;
    }
  }

  .welcome {
    color: #757680;
  }

  .rms {
    color: variables.$middle-purple;
  }

  .warningText {
    color: variables.$white;
  }

  .goBack {
    color: variables.$middle-purple;
  }

  .arrow {
    path {
      stroke: variables.$middle-purple;
    }
  }
}

@media screen and (max-height: 746px) {
  .mobileWarning {
    padding: 50px 40px 40px;
  }
}

@media screen and (max-height: 660px) {
  .logo {
    margin-bottom: 0;
  }
}

@media screen and (max-height: 580px) {
  .warningText {
    margin-bottom: 40px;
  }
}

@media screen and (max-height: 540px) {
  .mobileWarning {
    height: auto;
  }
}
