@use "../../styles/variables";

.buttonSecondary {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;

  padding: 8px 16px;

  border: 1px solid variables.$purple;
  border-radius: 10px;

  transition: background-color 0.1s;

  &.small {
    height: 24px;
    padding: 4px 8px;

    border-radius: 5px;

    .text {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &.large {
    height: 40px;
  }

  &:hover {
    background-color: variables.$light-hover;
  }

  &:focus {
    background-color: variables.$light-accent;
  }
}

.fullWidth {
  width: 100%;
}

.disabled {

}

body:global(.dark) {
  .buttonSecondary {
    &:hover {
      background-color: variables.$ui-elements;
    }

    &:focus {
      background-color: variables.$hover;
    }
  }
}
