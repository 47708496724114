@use "../../styles/variables";

.coinRate {
  display: inline-flex;
  gap: 8px;
  align-items: center;
}

.icon {
  flex-shrink: 0;

  width: 16px;
  height: auto;
}

.text {
  font-size: 14px;
  line-height: 20px;
}

.price {
  font-weight: 500;
}

.value {
  display: flex;
  flex-shrink: 0;
}

.up {
  color: variables.$green;

  &::before {
    content: "+";
  }
}

.down {
  color: variables.$orange;
}

body:global(.dark) {
  .up {
    color: variables.$green;
  }

  .down {
    color: variables.$orange;
  }
}
