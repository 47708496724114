@use "../../styles/variables";

.switch {
  width: 22px;
}

.hiddenInput {
  display: none;
}

.label {
  cursor: pointer;

  position: relative;

  display: block;

  height: 12px;

  background: #9b9ca9;
  border-radius: 50px;

  &::before {
    content: "";

    position: absolute;
    top: 0;
    left: 0;

    display: block;

    width: 100%;
    height: 100%;

    opacity: 0;
    border-radius: 16px;

    transition: opacity 0.1s ease;
  }

  &::after {
    content: "";

    position: absolute;
    top: 1px;
    left: 1px;

    display: block;

    width: 10px;
    height: 10px;

    background: variables.$white;
    border-radius: 50%;

    transition: left 0.1s ease;
  }
}

.hiddenInput:checked {
  & + .label {
    background: variables.$purple;

    &::after {
      left: calc(100% - 11px);
    }
  }
}

.large {
  width: 44px;

  .label {
    height: 24px;

    &::after {
      top: 2px;
      left: 2px;

      width: 20px;
      height: 20px;
    }
  }

  .hiddenInput:checked {
    & + .label {
      &::after {
        left: calc(100% - 22px);
      }
    }
  }
}
