@use "../../../../styles/variables";

.title {
  font-size: 29px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
}

.topWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 24px;
}

.tableWrapper {
  padding-right: 32px;
}

.topLeftWrapper {
  display: flex;
  gap: 32px;
  align-items: center;
}

.topRightWrapper {
  display: flex;
  gap: 8px;
}

.emptyBalance {
  display: flex;
  flex-direction: column;
  gap: 27px;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: calc(100vh - 216px);
}

.legendaWrapper {
  display: flex;
  gap: 16px;
  align-items: center;

  margin-right: 54px;
}

.columnFilter {
  position: fixed;
  z-index: 20;
  top: 215px;
  right: 0;

  display: flex;

  height: calc(100% - 216px);

  border-left: 2px solid variables.$purple;
}

.columnBar {
  cursor: pointer;

  width: 32px;
  height: 100%;
  padding-top: 18px;

  background-color: variables.$light-hover;

  transition: background-color 0.1s;

  &:hover {
    background-color: variables.$light-accent;
  }

  .columnBarContentIcon path {
    stroke: variables.$light-purple;

    transition: 0.1s stroke;
  }
}

.isEnabledColumnBar {
  background-color: variables.$light-accent;

  .columnBarContentText {
    color: variables.$purple;
  }

  .columnBarContentIcon path {
    stroke: variables.$purple;
  }
}

.columnBarContent {
  transform: rotate(90deg);

  display: flex;
  gap: 24px;
  align-items: center;
}

.columnBarContentText {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: variables.$dark-text;
}

.columnBarContentIcon {
  transform: rotate(90deg);

  flex-shrink: 0;
}

.columnCheckboxes {
  width: 0;

  background-color: variables.$white;

  transition: 0.1s width;

  & * {
    visibility: hidden;
    opacity: 0;
  }
}

.checkboxesWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.columnCheckboxesOpen {
  display: flex;
  flex-direction: column;
  gap: 10px;

  width: 180px;
  padding: 9px 16px;

  & * {
    visibility: visible;
    opacity: 1;
  }
}

.defaultsBtn {
  margin-top: 14px;
}

.customViewTable {
  margin-bottom: 24px;
}

.addAccountsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  margin-top: 300px;
}

body:global(.dark) {
  .columnFilter {
    border-left: 2px solid variables.$middle-purple;
  }

  .columnBar {
    background-color: variables.$ui-elements;

    &:hover {
      background-color: variables.$hover;
    }
  }

  .columnCheckboxes {
    background-color: variables.$panels-bg;
  }

  .columnBarContentIcon path {
    stroke: variables.$default-text;
  }

  .isEnabledColumnBar {
    .columnBarContentIcon path {
      stroke: variables.$middle-purple;
    }
  }
}
