@use "../../styles/variables";

.backdrop {
  position: fixed;
  z-index: 19;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;
}

.filtersBtmSection {
  height: 40px;

  background-color: variables.$light-hover;
  border: 1px solid variables.$light-hover;
  border-top: 0;
  border-bottom: 0;
}

.coinFiltersBtmSection {
  display: flex;
  align-items: center;

  height: 40px;
}

.filterLineWrapper {
  position: relative;

  display: flex;
  gap: 16px;

  padding: 0 17px 0 16px;

  &::after {
    content: "";

    position: absolute;
    top: 0;
    right: 0;

    display: block;

    width: 1px;
    height: 100%;

    background-color: variables.$light-purple;
  }
}

.filtersRailWrapper {
  position: relative;

  display: flex;
  align-items: center;

  height: 40px;
  padding: 0 16px;
}

.scrollButton {
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 40px;

  background-color: variables.$light-hover;

  &:hover {
    svg path {
      stroke: variables.$purple;
    }
  }
}

.filtersRail {
  position: relative;

  display: flex;
  gap: 16px;
  align-items: center;

  transition: left 0.3s;
}

.otherCoinsPopup {
  position: absolute;
  z-index: 20;
  top: calc(100% + 8px);
  left: 0;

  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  width: 225px;
  padding: 16px;

  visibility: hidden;
  opacity: 0;
  background-color: variables.$white;
  border: 2px solid variables.$purple;
  border-radius: 5px;
  box-shadow: 0 4px 30px rgb(54 56 84 / 0.25);

  transition: opacity 0.1s, visibility 0.1s;

  &.otherCoinsPopupOpen {
    visibility: visible;
    opacity: 1;
  }
}

.coinsPopupCheckbox {
  flex: 45%;
}

.heading {
  margin-bottom: 9px;
}

.otherCoinsBtn {
  position: relative;

  padding: 4px 8px;

  border-radius: 5px;
}

.otherCoinsBtnText {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.close {
  position: absolute;
  top: 12px;
  right: 12px;

  width: 24px;
  height: 24px;

  background-image: url("../../assets/images/x.svg");
  background-repeat: no-repeat;
  background-position: center;
}

body:global(.dark) {
  .filtersBtmSection {
    background-color: variables.$panels-bg;
    border: 1px solid variables.$accent;
  }

  .filterLineWrapper {
    &::after {
      background-color: variables.$purple;
    }
  }

  .otherCoinsPopup {
    background-color: variables.$panels-bg;
    box-shadow: none;
  }

  .close {
    background-image: url("../../assets/images/xDarkTheme.svg");
  }
}
