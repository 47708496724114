@use "../../styles/variables";

.content {
  display: flex;

  height: 100%;

  background-color: variables.$light-bg;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.sidebar {
  flex-shrink: 0;

  width: 192px;
  height: 100%;
  padding-top: 16px;
  padding-bottom: 16px;

  background-color: variables.$white;
  border-bottom-left-radius: 10px;
}

.sidebarButton {
  justify-content: flex-start;

  padding: 8px 16px;

  text-align: left;

  border-radius: 0;
}

.sidebarButtonInactive {
  background-color: transparent;

  &:hover {
    background-color: variables.$light-bg;
  }

  &:active {
    background-color: variables.$light-hover;
  }

  span {
    color: variables.$dark-text;
  }
}

.dummyButton {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 37px;
  padding: 8px 16px;
}

.comingSoon {
  position: relative;
  left: 12px;

  width: 79px;
  height: 18px;
}

.manage {
  position: relative;

  width: 100%;
}

.deleteBackdrop {
  position: absolute;
  top: 64px;
  left: 0;

  width: 100%;
  height: 100%;

  background: rgb(variables.$white / 0.5);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.deleteWrapper {
  position: absolute;
  right: 0;
  bottom: 0;

  width: 1002px;
  height: 117px;
  padding: 24px;

  background-color: variables.$light-hover;
  border-top: 2px solid variables.$purple;
  border-bottom-right-radius: 10px;
}

.deleteHeading {
  margin-bottom: 16px;

  font-size: 22px;
  font-weight: 600;
  line-height: 29px;
  color: variables.$purple;
  text-transform: uppercase;
}

.confirmationText {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;

  em {
    font-style: normal;
    color: variables.$purple;
  }
}

.deleteMain {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttonsWrapper {
  display: flex;
  gap: 8px;
}

.deleteBtnYes {
  width: 88px;
}

.deleteBtnNo {
  width: 88px;
}

.newAccountScreenModal {
  width: 1136px;
  height: 854px;
}

.newCustomInstumentModal {
  width: 792px;
  height: auto;
}

.newPositionModal {
  width: 838px;
  height: 632px;
}

.newView {
  height: 165px;
}

.newViewNote {
  margin-bottom: 8px;

  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.newViewInput {
  width: 394px;
}

.newViewBottom {
  align-items: flex-end;
}

body:global(.dark) {
  .content {
    background-color: variables.$background;
  }

  .sidebar {
    background-color: variables.$panels-bg;
  }

  .sidebarButtonInactive {
    &:hover {
      background-color: variables.$ui-elements;
    }

    &:active {
      background-color: variables.$hover;
    }
  }

  .deleteBackdrop {
    background: rgb(0 0 0 / 0.6);
  }

  .deleteHeading {
    color: variables.$middle-purple;
  }

  .deleteWrapper {
    background-color: variables.$panels-bg;
  }

  .confirmationText {
    em {
      color: variables.$middle-purple;
    }
  }
}
