@use "../../styles/variables";

.avatar {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid transparent;
  border-radius: 50%;

  transition: border-color 0.1s ease;
}

.small {
  width: 24px;
  height: 24px;
}

.large {
  width: 54px;
  height: 54px;

  &::before {
    width: 48px;
    height: 48px;
  }

  .abbreviation {
    font-size: 22.4px;
    font-weight: 400;
    line-height: 28px;
  }
}

.abbreviation {
  color: variables.$light-hover;
}

.isCurrentAvatar {
  position: relative;

  &::before {
    content: "";

    position: absolute;
    z-index: 10;
    top: -1px;
    left: -1px;

    display: block;

    border: 3px solid variables.$white;
    border-radius: 50%;
  }

  &::after {
    content: "";

    position: absolute;
    top: -4px;
    left: -4px;

    display: block;

    width: 100%;
    height: 100%;

    border: 4px solid variables.$purple;
    border-radius: 50%;
  }
}

.variant0 {
  background-color: variables.$purple;
}

.variant1 {
  background-image: url("../../assets/images/avatar1.svg");
}

.variant2 {
  background-image: url("../../assets/images/avatar2.svg");
}

.variant3 {
  background-image: url("../../assets/images/avatar3alt.svg");
}

.variant4 {
  background-image: url("../../assets/images/avatar4.svg");
}

body:global(.dark) {
  .abbreviation {
    color: variables.$default-text;
  }

  .variant3 {
    background-image: url("../../assets/images/avatar3.svg");
  }

  .isCurrentAvatar {
    &::before {
      border-color: variables.$panels-bg;
    }
  }
}
