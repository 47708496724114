@use "../../styles/variables";

.buttonPrimary {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 12px;

  background-color: variables.$purple;

  transition: 0.1s background-color;

  &:hover {
    background-color: variables.$middle-purple;
  }

  &:active {
    background-color: variables.$dark-purple;
  }
}

.text {
  font-size: 16px;
  font-weight: 500;
  color: variables.$white;
}

.small {
  height: 24px;
  padding: 0 8px;

  border-radius: 5px;

  .text {
    font-size: 12px;
    line-height: 16px;
  }
}

.middle {
  height: 40px;

  border-radius: 10px;
}

.large {
  height: 48px;

  border-radius: 10px;
}

.fullwidth {
  width: 100%;
}

.inactive {
  background-color: variables.$light-hover;

  &:hover {
    background-color: variables.$light-accent;
  }

  &:focus {
    background-color: variables.$light-accent;
  }

  .text {
    color: variables.$purple;
  }
}

.disabled {
  cursor: not-allowed;

  background-color: variables.$light-purple;

  &:hover {
    background-color: variables.$light-purple;
  }

  &:active {
    background-color: variables.$light-purple;
  }

  &.inactive {
    background-color: variables.$light-hover;

    &:hover {
      background-color: variables.$light-hover;
    }

    &:active {
      background-color: variables.$light-hover;
    }

    .text {
      color: variables.$light-blue-text;
    }
  }
}

.withIcon {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;

  padding: 0 16px;
}

body:global(.dark) {
  .text {
    color: variables.$header-text;
  }

  .disabled {
    background-color: variables.$ui-elements;

    &:hover {
      background-color: variables.$ui-elements;
    }

    &:active {
      background-color: variables.$ui-elements;
    }

    .text {
      color: variables.$secondary-text;
    }

    svg path {
      stroke: variables.$secondary-text;
    }

    &.inactive {
      background-color: variables.$ui-elements;

      &:hover {
        background-color: variables.$ui-elements;
      }

      &:active {
        background-color: variables.$ui-elements;
      }

      .text {
        color: variables.$secondary-text;
      }
    }
  }
}
