@use "../../../../styles/variables";

.header {
  position: relative;

  display: flex;
  justify-content: space-between;

  width: 100%;
  padding: 24px;
}

.nameWrapper {
  display: flex;
  gap: 12px;
}

.back {
  position: relative;
  top: 2px;
  transform: rotate(180deg);

  width: 12px;
  height: 24px;

  background-image: url("../../../../assets/images/custom-view-open.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.heading {
  margin-bottom: 4px;

  font-size: 22px;
  font-weight: 700;
  line-height: 29px;
  text-transform: uppercase;
}

.description {
  font-size: 14px;
  line-height: 18px;
}

.accountName {
  font-size: 14px;
  font-weight: 600;
  color: variables.$purple;
}

.assets {
  display: flex;
  gap: 16px;

  padding: 0 24px;
}

.assetItem {
  width: 228px;
}

.assetAccountName {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: space-between;

  height: 26px;
  margin-bottom: 8px;
  padding: 0 8px;

  background: variables.$light-purple;
  border-radius: 5px;
}

.assetAccountNameLeftSide {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.assetAccountNameRightSide {
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: center;

  width: 48px;
  padding: 0 0 0 8px;

  border-left: 1px solid white;
}

.weight {
  cursor: pointer;

  &:hover {
    .tooltip {
      visibility: visible;
      opacity: 1;
    }
  }
}

.assetAccountNameLabel {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: variables.$white;
  text-transform: uppercase;
}

.exchangeSection {
  position: relative;

  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

  height: 26px;
  margin-bottom: 1px;
  padding: 0 8px;

  background-color: variables.$white;

  &::before {
    content: "";

    position: absolute;
    top: 0;
    left: 0;

    width: 2px;
    height: 100%;

    background-color: variables.$green;
  }
}

.exchangeName {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}

.portfolioSection {
  gap: 20px;
  justify-content: space-between;

  &::before {
    background-color: variables.$light-purple;
  }

  .exchangeName {
    font-weight: 400;
  }
}

.portfolioLeftSide {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.portfolioRightSide {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: center;

  width: 48px;
  padding: 0 0 0 8px;

  border-left: 1px solid white;
}

.edit {
  input {
    width: 42px;
    height: 19px !important;
    padding: 2px 10px !important;

    font-size: 12px !important;

    border-radius: 2px !important;
  }
}

.tooltip {
  position: absolute;
  z-index: 20;
  top: calc(100% + 8px);
  right: 8px;

  padding: 8px 16px;

  visibility: hidden;
  opacity: 0;
  background-color: variables.$light-bg;
  border: 1px solid variables.$light-accent;
  border-radius: 7px;
  box-shadow: 0 4px 10px rgb(68 61 255 / 0.05);
}

.tooltipText {
  font-size: 12px;
}

.disabledPortfolioRightSide {
  border-color: #abb8db;
}

.disabledWeight {
  color: #abb8db;
}

body:global(.dark) {
  .assetAccountName {
    background-color: variables.$purple;
  }

  .exchangeSection {
    background-color: variables.$panels-bg;
  }

  .tooltip {
    background-color: variables.$background;
    border-color: variables.$accent;
  }

  .disabledPortfolioRightSide {
    border-color: variables.$secondary-text;
  }

  .disabledWeight {
    color: variables.$secondary-text;
  }
}
