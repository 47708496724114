@use "../../styles/variables";

.signInForm {
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translate(50%, -50%);

  display: flex;
  flex-direction: column;
  gap: 64px;
  align-items: center;

  width: 744px;
  height: 605px;
  padding: 64px 55px;

  background: variables.$white;
  border: 3px solid variables.$purple;
  border-radius: 10px;
}

.confirmationLink {
  height: 593px;
  padding: 80px 64px 64px;
}

.confirmationWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 549px;
  height: 324px;

  text-align: center;
}

body:global(.dark) {
  .signInForm {
    background-color: variables.$background;
  }
}
