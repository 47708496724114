@use "../../styles/variables";

.table {
  user-select: text;

  position: relative;

  border-spacing: 0;

  * {
    user-select: text;
  }
}

.fixed {
  table-layout: fixed;
}

.titleWrapper {
  display: flex;

  width: fit-content;
}

.dummy {
  flex-shrink: 0;

  width: 102px;
  height: 37px;

  background-color: variables.$light-purple;
}

.wideDummy {
  width: 143px;
}

body:global(.dark) {
  .dummy {
    background-color: variables.$purple;
  }
}
