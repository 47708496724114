@use "../../styles/variables";

.typography {
  font-family: "Space Grotesk", Arial, Helvetica, sans-serif;
  color: variables.$dark-text;

  transition: color 0.1s;
}

.dark {
  color: variables.$dark-text;
}

.black {
  color: variables.$black;
}

.secondary {
  color: variables.$secondary-text;
}

.white {
  color: variables.$white;
}

.purple {
  color: variables.$purple;
}

.isUppercase {
  text-transform: uppercase;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.w400 {
  font-weight: 400;
}

.w500 {
  font-weight: 500;
}

.w600 {
  font-weight: 600;
}

.w700 {
  font-weight: 700;
}

body:global(.dark) {
  .typography,
  .dark {
    color: variables.$default-text;
  }

  .secondary {
    color: variables.$secondary-text;
  }

  .white {
    color: variables.$white;
  }

  .purple {
    color: variables.$purple;
  }

  .black {
    color: variables.$black;
  }
}
