@use "../../styles/variables";

.modalSidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 200px;
  height: 639px;
  padding: 16px 12px 24px;

  background-color: variables.$white;
  border-bottom-left-radius: 10px;
}

.invertedBackground {
  background-color: variables.$light-bg;
}

.modalSidebarButton {
  justify-content: flex-start;

  height: 37px;
  padding: 8px 16px;

  text-align: left;

  border-radius: 7px;
}

.modalSidebarButtonInactive {
  background-color: transparent;

  &:hover {
    background-color: variables.$light-bg;
  }

  &:active {
    background-color: variables.$light-hover;
  }

  span {
    color: variables.$dark-text;
  }
}

.modalSidebarButtonDisabled {
  background-color: transparent !important;

  span {
    color: variables.$secondary-text !important;
  }
}

.bottomButton {
  display: flex;
  gap: 8px;
  align-items: center;

  padding: 0 8px;
}

.deactivateText {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 16px;
  text-decoration: underline;
}

.user {
  path {
    stroke: variables.$dark-text;
  }
}

body:global(.dark) {
  .modalSidebar {
    background-color: variables.$panels-bg;
  }

  .modalSidebarButtonInactive {
    &:hover {
      background-color: variables.$ui-elements;
    }

    &:active {
      background-color: variables.$hover;
    }
  }

  .user {
    path {
      stroke: variables.$default-text;
    }
  }

  .modalSidebarButtonDisabled {
    span {
      color: variables.$secondary-text !important;
    }
  }
}
