@use "../../../../styles/variables";

.apiKeysScreen { }

.topBar {
  display: flex;
  gap: 32px;
  justify-content: flex-end;

  height: 69px;
  padding: 12px 40px;

  text-align: right;

  background: #f0f4ff;
}

.accountName {
  margin-bottom: 2px;

  font-size: 22px;
  font-weight: 500;
  line-height: 29px;
  color: variables.$purple;
}

.accountLabel {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: #9b9ca9;
  text-align: right;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  height: 647px;
  padding: 40px;
  padding-top: 37px;
}

.contentTop {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contentHeadingWrapper {
  display: flex;
  gap: 8px;
  align-items: center;

  margin-bottom: 32px;
}

.contentHeading {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
}

.tip {
  position: relative;

  width: 455px;
  margin-bottom: 55px;
  padding-left: 28px;
}

.tipInfo {
  position: absolute;
  top: 0;
  left: 0;

  width: 16px;
  height: 16px;

  background-image: url("../../../../assets/images/exchangeUpdateKeys.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.tipText {
  font-size: 12px;
  line-height: 20px;
}

.buttonsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.createButton {
  width: 294px;
  height: 48px;
  margin-bottom: 25px;
}

.cancelButton {
  font-family: "Space Grotesk", Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.cancelButtonsWrapper {
  display: flex;
  gap: 24px;
}

.formWrapper {
  width: 508px;
}

.wideFormWrapper {
  width: 1056px;
}

.inputLabel {
  margin-bottom: 8px;

  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
}

.inputWrapper {
  margin-bottom: 16px;
}

.input {
  height: 56px !important;
  padding: 16px !important;

  font-size: 16px;
  line-height: 20px;

  background: #f0f4ff;
  border-radius: 10px;
}

.okxWrapper {
  display: flex;
  flex-wrap: wrap;
  row-gap: 0;
  column-gap: 40px;

  .inputWrapper {
    flex: 48%;
  }
}

.successIcon {
  margin-top: 96px;
  margin-bottom: 56px;
}

.newAccountName {
  margin-bottom: 8px;

  font-size: 22px;
  font-weight: 600;
  line-height: 29px;
  color: variables.$purple;
}

.successNote {
  font-size: 22px;
  font-weight: 400;
  line-height: 29px;
}

.successTopWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.successWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 716px;
  padding: 108px 338px 42px;
}

.error {
  width: 332px;
  margin: 0 auto 32px;
  padding: 8px 16px;

  text-align: center;

  background: rgb(248 205 205 / 0.1);
  border: 1px solid #ffc9c9;
  border-radius: 7px;
}

.okxError {
  margin-bottom: 38px;
}

.chooseExchangeWrapper {
  display: flex;
  gap: 24px;
  align-items: center;

  margin-bottom: 33px;
}

.chooseExchangeWrapperHeading {

}

body:global(.dark) {
  .topBar {
    background-color: variables.$panels-bg;
  }

  .cancelButton {
    color: variables.$default-text;
  }

  .inputLabel {
    color: variables.$default-text;
  }

  .error {
    background-color: variables.$light-red;
    border-color: variables.$middle-red;
  }

  .accountName {
    color: variables.$middle-purple;
  }
}
