@use "../../styles/variables";

.sidebarLogo {
  position: absolute;
  top: 40px;
  left: 42px;

  display: flex;
  gap: 20px;
  align-items: center;
}

.icon {
  flex-shrink: 0;

  width: 47px;
  height: 44px;

  transition: width 0.2s, height 0.2s, transform 0.1s ease-in-out;
}

.text {
  position: relative;
  top: 2px;

  flex-shrink: 0;

  font-family: Onest, Arial, Helvetica, sans-serif;

  transition: opacity 0.1s ease-in-out;
  transition-delay: 0.2s;
}

.darkVariant {

}

.collapsed {
  .icon {
    transform: translate(-14px, -23px);

    width: 36px;
    height: 34px;
  }

  .text {
    opacity: 0;

    transition-delay: 0s;
  }
}
