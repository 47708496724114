@use "../../styles/variables";

.buttonHeader {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  width: 38px;
  height: 38px;

  border-radius: 8px;

  transition: background-color 0.1s;

  &:hover {
    background-color: variables.$light-hover;
  }

  &:active {
    background-color: variables.$light-hover;
  }
}

body:global(.dark) {
  .buttonHeader {
    &:hover {
      background-color: variables.$ui-elements;
    }

    &:active {
      background-color: variables.$hover;
    }
  }
}
