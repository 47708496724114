@use "../../styles/variables";

.modalUserProfile {
  width: 838px;
  height: 807px;

  .input {
    input {
      background-color: variables.$light-bg;
    }
  }
}

.wrapper {
  position: relative;

  display: flex;
}

.trigger1 {
  cursor: pointer;

  position: absolute;
  top: 16px;
  left: 223px;

  width: 81px;
  height: 40px;
}

.trigger2 {
  cursor: pointer;

  position: absolute;
  top: 16px;
  left: 319px;

  width: 112px;
  height: 40px;
}

.trigger3 {
  cursor: pointer;

  position: absolute;
  top: 16px;
  left: 440px;

  width: 160px;
  height: 40px;
}

.trigger4 {
  cursor: pointer;

  position: absolute;
  top: 54px;
  left: 13px;

  width: 177px;
  height: 40px;
}

.trigger5 {
  cursor: pointer;

  position: absolute;
  top: 16px;
  left: 13px;

  width: 177px;
  height: 40px;
}

.blur {
  &::after {
    content: "";

    position: absolute;
    top: 1px;
    left: 0;

    display: block;

    width: 100%;
    height: 100%;

    background: rgb(14 14 97 / 0.3);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}

.rightSection {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 637px;
  padding: 24px;
}

.tabsWrapper {
  margin-bottom: 40px;
}

.profileButtons {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}

.actionButton {
  width: 100px;
}

.messageBox {
  position: absolute;
  bottom: 78px;

  width: 589px;
}

.inputLabel {
  margin-bottom: 8px;

  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 20px;
}

.inputWrapper {
  margin-bottom: 32px;
}

.input {
  input {
    height: 56px !important;
  }
}

.userEmail {
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 20px;
  color: variables.$middle-purple !important;
}

.tab {
  position: relative;

  padding-bottom: 6px;

  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  line-height: 20px;
  color: variables.$dark-text;
  text-transform: uppercase;

  &.tabActive {
    color: variables.$purple;

    &::after {
      content: "";

      position: absolute;
      bottom: 0;
      left: 0;

      display: block;

      width: 100%;
      height: 2px;

      background-color: variables.$middle-purple;
    }
  }
}

.timezoneHeading {
  margin-bottom: 4px;
}

.timezoneDescription {
  display: block;

  margin-bottom: 24px;
}

.timezoneDropdown {
  width: 320px;
  margin-bottom: 32px;
}

.radioButtons {
  display: flex;
  gap: 24px;
}

body:global(.dark) {
  .modalUserProfile {
    .input {
      input {
        background-color: variables.$ui-elements;
      }
    }
  }

  .blur {
    &::after {
      background-color: rgb(0 0 0 / 0.3);
    }
  }

  .tab {
    color: variables.$secondary-text;

    &.tabActive {
      color: variables.$default-text;
    }
  }

  .apiLink {
    color: variables.$middle-purple;
  }

  .apiTable {
    th {
      color: variables.$secondary-text;
    }

    td {
      color: variables.$default-text;
    }
  }

  .deleteConfirmation {
    background-color: #0006;
  }

  .deleteContent {
    background-color: variables.$panels-bg;
    border-top: 2px solid variables.$middle-purple;
  }

  .deleteTitle {
    color: variables.$middle-purple;
  }
}
